<template>
  <v-form ref="form" style="width: 100%;" @keydown.enter="submit" @submit.prevent="login">
    <slot name="username">
      <v-text-field
        :label="$t('common.username')"
        outlined
        :prepend-inner-icon="mdiAccount"
        v-model="form.username"
        :rules="rules.required"
        data-cy="login-username"
        autocomplete="username"
      ></v-text-field>
    </slot>
    <slot name="password">
      <v-text-field
        :label="$t('common.password')"
        outlined
        :rules="rules.password"
        v-model="form.password"
        type="password"
        :prepend-inner-icon="mdiLock"
        data-cy="login-password"
        autocomplete="current-password"
        class="mb-0"
      ></v-text-field>
    </slot>
    <v-alert type="error" v-for="error in errors" :key="error">
      {{ error }}
    </v-alert>
    <slot name="loginButton">
      <v-btn
        block
        class="mb-2 text--capitalize d-block ml-auto mr-auto"
        :color="buttonColor"
        :loading="isLoading"
        data-cy="login-buttonLogin"
        type="submit"
      >
          {{ $t('common.buttons.login') }}
      </v-btn>
    </slot>
    <slot name="forgotPassword">
      <v-container v-if="showForgotPassword">
          <ForgotPasswordModal :email="form.username" :label="$t('common.forgotPasswordHeading')" />
      </v-container>
    </slot>
  </v-form>
</template>

<script>
import { mapActions } from 'vuex';
import { mdiAccount, mdiLock } from '@mdi/js';
import auth from '@codehq/aurora-app-core/src/mixins/auth';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import ForgotPasswordModal from './ForgotPasswordModal.vue';

export default {
  name: 'Login',
  components: {
    ForgotPasswordModal,
  },
  mixins: [auth, loading],
  props: {
    buttonColor: {
      type: String,
      default: 'primary',
    },
    textColor: {
      type: String,
      default: '',
    },
    labelUsername: {
      type: String,
      default: 'Username',
    },
    labelPassword: {
      type: String,
      default: 'Password',
    },
    showForgotPassword: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      mdiAccount,
      mdiLock,
      form: {
        username: '',
        password: '',
      },
      rules: {
        email: [
          (v) => !!v || 'This field is required',
          (v) => /^[A-z0-9\-.]{3}.*@[A-z0-9]*\.[A-z]{2}.*$/gi.test(v) || 'Please provide valid E-Mail',
        ],
        password: [
          (v) => !!v || 'This field is required',
          (v) => v.length > 7 || 'Password should be longer',
        ],
        required: [
          (v) => !!v || 'This field is required',
        ],
      },
      tab: null,
    };
  },
  methods: {
    ...mapActions('auth', ['LOGIN_auth']),
    async login() {
      if (this.$refs.form.validate()) {
        try {
          this.isLoading = true;
          const { username, password } = this.form;
          this.$log.info(`Logging on ${username}`);
          await this.$auth.login(username, password);
          this.$root.$emit('auth:login', username);
        } catch (error) {
          this.SET_error(error.response.data.error_description);
          this.errors = [error.response.data.error_description];
          this.$log.error(error.response.data.error_description);
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>

<style scoped>
.c-forget-password {
  text-decoration: underline;
  margin-top: -10px;
}

.c-remembet-me {
  margin-top: -10px !important;
}

h4 {
   width: 100%;
   text-align: center;
   border-bottom: 1px solid #000;
   line-height: 0.1em;
   margin: 10px 0 20px;
}

h4 span {
    background:#fff;
    padding:0 10px;
}
</style>
