import Vue from 'vue';
import axios from 'axios';
import auroraModule from '@codehq/aurora-app-core/src/plugins/auroraModule';
import { components } from '@codehq/aurora-app-core/src';
import ButtonProgress from '@codehq/aurora-app-core/src/components/ButtonProgress.vue';
import { Auth, AuthPlugin } from '@codehq/aurora-auth';
import { Client, ClientPlugin } from '@codehq/aurora-net';
import LocaleSwitcher from '@codehq/aurora-app-core/src/components/LocaleSwitcher.vue';
import TableBasicButtons from '@codehq/aurora-app-core/src/components/TableBasicButtons.vue';
import VueMask from 'v-mask';
import VuetifyConfirm from 'vuetify-confirm';

import { o } from 'odata';

import App from './App.vue';
import AutoSelect from './components/AutoSelect.vue';
import CategoriesMenu from './components/CategoriesMenu.vue';
import DateTimePicker from './components/DateTimePicker.vue';
import DatePickerSmall from './components/DatePickerSmall.vue';
import vuetify from './plugins/vuetify';
import formatting from './plugins/formatting';
import store from './store';
import { router, routes } from './router';
import { i18n, messages } from './i18n';

Vue.config.productionTip = false;

// register global components
Vue.use(AuthPlugin, {
  baseUrl: process.env.VUE_APP_APIURL,
  client_id: process.env.VUE_APP_CLIENT_ID,
  client_secret: process.env.VUE_APP_CLIENT_SECRET,
});
Vue.use(ClientPlugin, {
  baseUrl: process.env.VUE_APP_APIURL,
  client_id: process.env.VUE_APP_CLIENT_ID,
  client_secret: process.env.VUE_APP_CLIENT_SECRET,
  apiClient: 'axios',
  timeout: 30000,
});
Vue.use(VuetifyConfirm, { vuetify });

const { ImageUpload } = components;

Vue.use(VueMask);

// register app components
Vue.component(AutoSelect.name, AutoSelect);
Vue.component(CategoriesMenu.name, CategoriesMenu);
Vue.component(DateTimePicker.name, DateTimePicker);
Vue.component(DatePickerSmall.name, DatePickerSmall);

// register aurora components and plugin
Vue.component(ButtonProgress.name, ButtonProgress);
Vue.component(LocaleSwitcher.name, LocaleSwitcher);
Vue.component(TableBasicButtons.name, TableBasicButtons);
Vue.component(ImageUpload.name, ImageUpload);

// setup store
const client = new Client({
  baseUrl: process.env.VUE_APP_APIURL,
  client_id: process.env.VUE_APP_CLIENT_ID,
  client_secret: process.env.VUE_APP_CLIENT_SECRET,
  apiClient: 'axios',
  timeout: 30000,
});
store.$http = client;

const auth = new Auth({
  baseUrl: process.env.VUE_APP_APIURL,
  client_id: process.env.VUE_APP_CLIENT_ID,
  client_secret: process.env.VUE_APP_CLIENT_SECRET,
});
store.$auth = auth;

// setup app plugins
Vue.use(auroraModule, {
  baseUrl: process.env.VUE_APP_APIURL,
  store,
});
Vue.use(formatting);

// Setup API
axios.defaults.baseURL = process.env.VUE_APP_APIURL;

o(process.env.VUE_APP_APIURL);

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),

  i18n,
  mounted() {
    this.$log.debug('Locales:');
    this.$log.debug(messages);
    this.$log.debug('Routes:');
    this.$log.debug(routes);
  },
}).$mount('#app');
