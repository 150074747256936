<template>
  <v-text-field
    dense
    :prepend-inner-icon="mdiMinus"
    :append-icon="mdiPlus"
    v-model="quantity"
    type="number"
    :min="1"
    solo
    hide-details
    @click:append="incrementQuantity"
    @click:prepend-inner="decrementQuantity"
    class="button-add-to-cart"
  >
    <template v-slot:append-outer>
      <!-- <v-btn-toggle dense shaped rounded color="primary"> -->
        <v-btn
          color="primary"
          class="mt-0"
          min-width="100"
          @click="addToCart"
        >
          <v-icon v-if="!addedToCart">{{ mdiBasketPlus }}</v-icon>
          <v-icon v-else-if="showCheck" color="success">{{ mdiCheck }}</v-icon>
          <v-icon v-else>{{ mdiBasketCheck }}</v-icon>
          <template v-slot:append-outer>
            <v-menu v-if="shops.length > 0" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  class="mt-0"
                  v-bind="attrs" v-on="on"
                  min-width="100"
                  elevation="4"
                >
                  <v-icon>{{ mdiArrowDown }}</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-text>
                <div
                  v-for="(item, index) in shops"
                  :key="index"
                  class="d-flex align-center justify-space-between"
                  style="pointer: hand"
                  @click="addShopItem(item)"
                >
                  <v-img
                    v-if="item.shop.bannerImageUrl"
                    :src="item.shop.bannerImageUrl"
                    height="50"
                    width="75"
                    contain
                    class="mr-5"
                  />
                  <div v-else>{{ item.shop.name }}</div>
                  <div class="text-h5">R {{  item.product.price }}</div>
                  <!-- <v-btn @click="addToCart">
                    <v-icon>{{ mdiBasketPlus }}</v-icon>
                  </v-btn> -->
                </div>
              </v-card-text>
              </v-card>
            </v-menu>
          </template>
        </v-btn>
        <v-menu v-if="shops.length > 1" offset-y left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              class="mt-0 pa-0"
              v-bind="attrs" v-on="on"
              width="30px"
              min-width="30px"
            >
              <v-icon>{{ mdiMenuDown }}</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-text>
            <div
              v-for="(item, index) in sortedShops"
              :key="index"
              class="d-flex align-center justify-space-between cursor-pointer"
              @click="addShopItem(item)"
            >
              <v-img
                v-if="item.shop?.bannerImageUrl"
                :src="item.shop?.bannerImageUrl"
                height="50"
                width="75"
                contain
                class="mr-5  cursor-pointer"
              />
              <div v-else class="text-h5 cursor-pointer">{{ item.shop?.name }}</div>
              <div class="text-h5 cursor-pointer">R {{  item.product?.price?.toFixed(2) }}</div>
              <!-- <v-btn @click="addToCart">
                <v-icon>{{ mdiBasketPlus }}</v-icon>
              </v-btn> -->
            </div>
          </v-card-text>
          </v-card>
        </v-menu>
      <!-- </v-btn-toggle> -->
    </template>
  </v-text-field>
</template>

<script>
import {
  mdiPlus, mdiMinus, mdiBasketPlus, mdiCheck, mdiBasketCheck, mdiArrowDown, mdiMenuDown,
} from '@mdi/js';
import { sortBy } from 'lodash';

export default {
  name: 'ButtonAddToCart',
  props: {
    shops: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      quantity: 1,
      addedToCart: false,
      showCheck: false,
      mdiArrowDown,
      mdiPlus,
      mdiMinus,
      mdiBasketPlus,
      mdiBasketCheck,
      mdiCheck,
      mdiMenuDown,
      items: [],
    };
  },
  computed: {
    sortedShops() {
      return sortBy(this.shops, 'product.price');
    },
  },
  methods: {
    incrementQuantity() {
      this.quantity += 1;
    },
    decrementQuantity() {
      if (this.quantity > 1) {
        this.quantity -= 1;
      }
    },
    addToCart() {
      const { quantity } = this;
      this.addedToCart = true;
      this.showCheck = true;
      setTimeout(() => {
        this.showCheck = false;
      }, 2000);
      this.$emit('add', {
        quantity,
      });
    },
    addShopItem(shop) {
      const { quantity } = this;
      this.addedToCart = true;
      this.showCheck = true;
      setTimeout(() => {
        this.showCheck = false;
      }, 2000);
      this.$emit('addShop', {
        quantity,
        shop,
      });
    },
  },
};
</script>
