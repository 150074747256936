import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('category', 'categories');

const customActions = {
  async LOAD_categories({ commit, rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('categories')
      .query({
        $filter: 'IsDeleted eq false',
        $expand: 'Parent($select=Name,Parent,Id,ParentId)',
        $select: 'Name,Parent,Id,ParentId',
      });
    commit('SET_categories', data);
    return data;
  },
};

export default { ...actions, ...customActions };
