<template>
  <router-link
    class="text-decoration-none"
    :to="{
      name: 'products-CategoryPage',
      query: { category: '002d8494-ce6f-ee11-810a-00155d97e129' } }"
  >
    <v-img
      src="../assets/banner1.svg"
      srcset="../assets/banner1-mobile.svg 480w, ../assets/banner1.svg 1024w"
      sizes="(max-width: 480px) 480px, 1024px"
      class="w-100"
      stretch
    />
  </router-link>
</template>

<script>
export default {
  name: 'HeroImage',
};
</script>
