<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="products"
      class="elevation-1"
      :loading="isLoading"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-btn
            color="success"
            data-cy="Product-button-new"
            :to="{
              name: 'products-EditProduct'
            }"
          >
            <v-icon class="mr-2">
              {{ mdiPlus }}
            </v-icon>
            {{ $t('common.buttons.new') }}
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.price="{ item }">
        {{ formatCurrency(item.price) }}
      </template>
      <template v-slot:item.salePrice="{ item }">
        <span v-if="item.salePrice">{{ formatCurrency(item.salePrice) }}</span>
      </template>
      <template v-slot:item.masterProduct.imageUrl="{ item }">
        <v-img
          v-if="item.masterProduct.imageUrl"
          :src="item.masterProduct.imageUrl"
          :alt="item.masterProduct.name"
          max-width="100"
          max-height="100"
        />
      </template>
      <template v-slot:item.actions="{ item }">
        <TableBasicButtons :editLink="{
            name: 'products-EditProduct',
            query: {id: item.id}
          }"
          @delete="remove(item)"
        />
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiPlus } from '@mdi/js';
import TableBasicButtons from '@codehq/aurora-app-core/src/components/TableBasicButtons.vue';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'ListProducts',
  module: 'Products',
  components: {
    TableBasicButtons,
  },
  mixins: [loading],
  meta: {
    breadcrumb:
      [{
        text: 'menu.home',
        to: { name: 'app-Home' },
      },
      {
        text: 'menu.products',
        to: { name: 'products-ListProduct' },
        disabled: true,
      },
      ],
  },
  data() {
    return {
      /**
      * Icons
      */
      mdiPlus,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('products', ['products']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: '',
          value: 'masterProduct.imageUrl',
        },
        {
          text: this.$$t('sku'),
          value: 'sku',
        },
        {
          text: this.$$t('name'),
          value: 'masterProduct.name',
        },
        {
          text: this.$$t('price'),
          value: 'price',
        },
        {
          text: this.$$t('salePrice'),
          value: 'salePrice',
        },
        {
          text: this.$$t('unitOfMeasure'),
          value: 'masterProduct.unitOfMeasure',
        },
        {
          text: this.$$t('stockOnHand'),
          value: 'stockOnHand',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_products();
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('products', ['LOAD_products', 'DELETE_product']),
    /** Delete Product from the collection
     */
    async remove(product) {
      this.isLoading = true;
      await this.DELETE_product(product.id);
      this.isLoading = false;
    },
  },
};
</script>

<docs>
# Product example

'''js
<Product />
'''
</docs>
