<template>
  <v-form
    lazy-validation
    v-model="valid"
    @submit.prevent="submit"
    ref="form"
  >
      <v-container class="text-center">
        <v-row>
          <v-col cols="12" md="6">
            <v-autocomplete
              filled
              :items="masterProducts"
              v-model="localProduct.masterProductId"
              item-text="name"
              item-value="id"
              label="Master Product"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              filled
              data-cy="FormProduct-sku"
              :label="$$t('sku')"
              v-model="localProduct.sku"
            />
          </v-col>
          <!-- <v-col cols="12" lg="4" xl="4">
            <v-text-field
              filled
              data-cy="FormProduct-name"
              :label="$$t('name')"
              v-model="localProduct.name"
            />
          </v-col> -->
          <v-col cols="12" md="4">
            <v-text-field
              filled
              type="number"
              data-cy="FormProduct-salePrice"
              :label="$$t('salePrice')"
              v-model="localProduct.salePrice"
              clearable
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              filled
              type="number"
              data-cy="FormProduct-price"
              :label="$$t('price')"
              v-model="localProduct.price"
            />
          </v-col>
          <!-- <v-col cols="12" lg="2" xl="2">
            <v-text-field
              filled
              data-cy="FormProduct-unitOfMeasure"
              :label="$$t('unitOfMeasure')"
              v-model="localProduct.unitOfMeasure"
            />
          </v-col> -->
          <v-col cols="12" md="2">
            <v-text-field
              filled
              type="number"
              data-cy="FormProduct-stockOnHand"
              :label="$$t('stockOnHand')"
              v-model="localProduct.stockOnHand"
            />
          </v-col>
          <v-col cols="12" md="2">
            <v-checkbox
              filled
              type="number"
              data-cy="FormProduct-isActive"
              :label="$$t('isActive')"
              v-model="localProduct.isActive"
            />
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col cols="12" md="4">
            <v-img contain v-if="localProduct.imageUrl" :src="localProduct.imageUrl" />
            <ImageUpload v-model="localProduct.imageUrl" />
          </v-col>
          <v-col cols="12" lg="8" xl="8">
            <v-textarea
              filled
              data-cy="FormProduct-description"
              :label="$$t('description')"
              v-model="localProduct.description"
              counter="2000"
            />
          </v-col>
        </v-row> -->
        <div class="d-flex justify-end mb-6">
          <v-btn color="default" outlined class="ml-4 btn-cancel" @click="cancel"
            data-cy="FormProduct-button-cancel">
            {{ $t('common.buttons.cancel') }}
          </v-btn>
          <v-btn color="primary" type="submit" class="ml-4" :loading="isSaving"
            data-cy="FormProduct-button-save">
              {{ $t('common.buttons.save') }}
            </v-btn>
        </div>
      </v-container>
  </v-form>
</template>

<script>
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import { mapState } from 'vuex';

export default {
  name: 'FormProduct',
  module: 'Products',
  mixins: [loading],
  props: {
    /**
    * Set the text box value. Default: undefined,
    */
    Product: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      /**
      * Hold form validation state
      */
      valid: false,
      /**
      * Local value used for property localProduct
      */
      localProduct: {},
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('masterProducts', ['masterProducts']),
  },
  watch: {
    Product() {
      this.localProduct = this.Product;
    },
  },
  created() {
    this.localProduct = this.Product;
  },
  methods: {
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    submit() {
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localProduct);
        this.isSaving = true;
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<docs>
# FormProduct example

'''js
<FormProduct :Product="Product" />
'''
</docs>
