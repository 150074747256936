import dayjs from 'dayjs';

export default {
  /**
  * Install the plugin
  */
  install(Vue) {
    // formatting mixin
    Vue.mixin({
      methods: {
        formatDateTime(dt) {
          return dayjs(dt).format('YYYY-MM-DD HH:mm');
        },
        formatCurrency(money) {
          const userLang = navigator.language || navigator.userLanguage;
          return `R ${new Intl.NumberFormat(userLang).format(money ?? 0)}`;
          // const currency = currencyCode ?? 'ZAR';
          // const opts = {
          //   currency, style: 'currency', currencyDisplay: 'symbol', minimumFractionDigits: 2,
          // };
          // return new Intl.NumberFormat(userLang, opts).format(money); // '$100.00'
        },
        formatNumber(money) {
          const userLang = navigator.language || navigator.userLanguage;
          return new Intl.NumberFormat(userLang).format(money ?? 0);
        },
        formatDate(dt) {
          return dayjs(dt).format('YYYY-MM-DD');
        },
        formatFromNow(dt) {
          return dayjs(dt).fromNow();
        },
      },
    });
  },
};
