const locales = require.context('./', true, /[A-Za-z0-9-_,\s]+\.json$/i);
const messages = {};
locales.keys().forEach((key) => {
  const arr = key.split('/');
  const locale = arr[1];
  if (arr[2]) {
    const obj = arr[2].replace('.json', '');
    if (!messages[locale]) {
      messages[locale] = {};
    }
    messages[locale][obj] = locales(key);
  }
});

export default messages;
