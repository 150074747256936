import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('masterProduct', 'masterProducts');

const customActions = {
  async LOAD_masterProductExpanded({ commit, rootGetters }, id) {
    const data = await odata.o(rootGetters)
      .get(`masterProducts(${id})`)
      .query({ $expand: 'products($expand=shop),brand' });
    commit('SET_masterProduct', data);
    return data;
  },
  async LOAD_masterProductsExpanded({ commit, rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('masterProducts')
      .query({
        $expand: 'brand,category,products($expand=shop($filter=isActive eq true and isDeleted eq false))',
        $filter: 'isActive eq true AND isDeleted eq false',
      });
    commit('SET_masterProducts', data);
    return data;
  },
  async LOAD_masterProductByBarcode({ commit, rootGetters }, barcode) {
    const data = await odata.o(rootGetters)
      .get('masterProducts')
      .query({
        $expand: 'brand,category,products($expand=shop($filter=isActive eq true and isDeleted eq false))',
        $filter: `barcode eq '${barcode}'`,
      });
    commit('SET_masterProducts', data);
    return data;
  },
};

export default { ...actions, ...customActions };
