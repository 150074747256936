<template>
  <v-container fluid>
    <v-card :loading="isLoading">
      <v-card-title primary-title>
        Search results for&nbsp;<b>{{ search }}</b>
        <!-- <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            :append-icon="mdiMagnify"
            label="Search"
            single-line
            hide-details></v-text-field> -->
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="3">
            <v-select
              v-model="selectedBrand"
              clearable
              :items="brands"
              item-text="name"
              item-value="id"
              label="Brand" />
          </v-col>
          <v-col cols="3">
            <v-select
              v-model="selectedCategory"
              clearable
              item-text="name"
              item-value="id"
              :items="categories"
              label="Category"
            />
          </v-col>
          <v-col cols="3">
            <v-select
              v-model="selectedSize"
              :items="sizes"
              label="Size"
              clearable />
          </v-col>
          <v-col cols="3">
            <!-- <v-text-field
              v-model="searchInResult"
              :append-icon="mdiMagnify"
              label="Search in results"
              single-line
              hide-details></v-text-field> -->
          </v-col>
        </v-row>
        <v-data-iterator
          :items="filteredProducts"
          :loading="loading"
          :items-per-page="itemsPerPage"
          :footer-props="{
            itemsPerPageOptions: [10, 25, 50, 100],
            showFirstLastPage: true,
            showCurrentPage: true,
            showPageSize: true,
            pageText: 'Page {0} of {1}'
          }"
        >
          <template v-slot:header>
          </template>
        <template v-slot:default="props">
        <v-row>
          <v-col cols="3"
            v-for="item in props.items" :key="item.id">
            <v-card style="width: 100%; height: 100%">
              <v-img v-if="item.brand" :src="item.brand.imageUrl" height="150" contain />
              <v-card-title primary-title>
                {{ item.name }}
              </v-card-title>
              <v-img :src="item.imageUrl" height="300" stretch />
              <v-card-text>
                <p>{{ item.description }}</p>
                <p>Size: {{ item.size }}</p>
                <v-row>
                  <v-col cols="12">
                    <v-row>
                      <v-col
                        class="d-flex align-stretch supplier-col"
                        v-for="(p, index) in item.products" :key="index" cols="12"
                      >
                        <v-card class="supplier-card" elevation="0">
                          <v-card-title primary-title>
                            Supplied By
                          </v-card-title>
                          <v-card-text>
                            <v-img contain height="75" :src="p.shop.bannerImageUrl" />
                              <h4 class="mt-3">Tel: <b>{{ p.shop.contactNumber }}</b></h4>
                              <h4>Operating Hours: <b>{{ p.shop.operatingHours }}</b></h4>
                              <h4>Price: <b>R{{ p.price }}</b></h4>
                              <h4>Available: <b>{{ p.stockOnHand }}</b></h4>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          </v-row>
          </template>
        </v-data-iterator>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiMagnify } from '@mdi/js';
import axios from 'axios';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'SearchResult',
  mixins: [loading],
  data() {
    return {
      mdiMagnify,
      products: [],
      loading: false,
      itemsPerPage: 10,
      masterProductId: this.$route.query.masterProductId,
      search: this.$route.query.search,
      selectedBrand: null,
      selectedCategory: null,
      selectedSize: null,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('brands', ['brands']),
    ...mapState('categories', ['categories']),
    ...mapState('masterProducts', ['masterProduct']),
    ...mapState('organizations', ['allOrganizations']),
    filteredProducts() {
      const { selectedBrand, selectedCategory, selectedSize } = this;
      let { products } = this;
      if (selectedBrand) {
        products = products.filter((p) => p.brand.id === selectedBrand);
      }
      if (selectedCategory) {
        products = products.filter((p) => p.category.id === selectedCategory);
      }
      if (selectedSize) {
        products = products.filter((p) => p.size === selectedSize);
      }
      return products;
    },
    sizes() {
      return this.products.map((p) => p.size);
    },
  },
  async mounted() {
    this.isLoading = true;
    // await this.LOAD_allOrganizations();
    await Promise.all([
      (async () => {
        await this.fetchProducts();
      })(),
      (async () => {
        await this.LOAD_brands();
      })(),
      (async () => {
        await this.LOAD_categories();
      })(),
    ]);
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('brands', ['LOAD_brands']),
    ...mapActions('categories', ['LOAD_categories']),
    ...mapActions('masterProducts', ['LOAD_masterProductExpanded']),
    ...mapActions('organizations', ['LOAD_allOrganizations']),
    async fetchProducts() {
      try {
        this.loading = true;
        console.debug(this.masterProductId);
        if (this.masterProductId) {
          this.products = await this.LOAD_masterProductExpanded(this.masterProductId);
        } else if (this.search) {
          const response = await axios.get(`/api/search?q=${this.search}`);
          this.products = response.data;
        }
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped>
.supplier-card {
  /* position: absolute;
  bottom: 0; */
  /* max-width: 90%; */
}
.supplier-col {
  /* min-height: 270px; */
}
</style>
