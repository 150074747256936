import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  data() {
    return {
      localOrganization: undefined,
      search: '',
    };
  },
  computed: {
    ...mapState('organizations', ['organizations', 'organization']),
  },
  watch: {
    organization() {
      this.localOrganization = this.organization;
    },
  },
  methods: {
    ...mapActions('organizations', ['LOAD_organizations']),
    ...mapMutations('organizations', ['SET_organization']),
    organizationChanged(org) {
      this.$log.info('organization changed', org);
      this.SET_organization(org);
      this.$root.$emit('organization.changed', org);
    },
    async refresh() {
      try {
        this.isLoading = true;
        await this.LOAD_organizations();
        this.$nextTick(() => {
          this.localOrganization = this.organization;
          this.$root.$emit('organizations.loaded', this.organizations);
        });
      } catch (error) {
        this.$log.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  created() {
    this.$root.$on('auth:login', async () => {
      await this.refresh();
    });
  },
  async mounted() {
    await this.refresh();
  },
};
