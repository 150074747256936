<template>
  <v-card v-if="item && item.masterProduct" class="h-100 w-100">
    <v-card-text>
      <v-row>
        <v-col cols="12" md="6" v-if="item.masterProduct">
          <v-img
            :src="item.masterProduct.imageUrl"
            height="200px"
            width="350px"
          >
            <div v-if="item.masterProduct.name.length > 0" class="text-h6 ml-2 font-weight-medium">
              {{ item.masterProduct.name }}
            </div>
            <div v-else class="text-h6">
              {{ item.masterProduct.description }}
            </div>
          </v-img>
        </v-col>
        <v-col cols="12" md="6" class="d-flex flex-column justify-space-between">
          <div style="flex-grow: 1;">
            <div
              :class="isMobile ? 'text-h5' : 'text-h4'"
              class="text-right"
              style="text-decoration: line-through;">
              {{ formatCurrency(item.price) }}
            </div>
            <div
              :class="isMobile ? 'text-h5' : 'text-h4'"
              class="text-right">
              {{ formatCurrency(item.salePrice) }}
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="text-center">
      <ButtonQuantity v-model="localQuantity" />
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapMutations } from 'vuex';
import ButtonQuantity from './ButtonQuantity.vue';

export default {
  name: 'ProductCheckoutCard',
  components: { ButtonQuantity },
  props: {
    /**
         * Set the product item. Default: undefined,
         */
    item: {
      type: Object,
      default: undefined,
    },
    quantity: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      localQuantity: this.quantity,
    };
  },
  watch: {
    localQuantity() {
      this.$emit('update:quantity', this.localQuantity);
    },
    quantity(val) {
      this.localQuantity = val;
    },
  },
  mounted() {
    this.localQuantity = this.quantity;
  },
  methods: {
    ...mapMutations('basket', ['ADD_basketItem']),
    addToCart({ quantity }) {
      const { item } = this;
      this.ADD_basketItem({
        masterProduct: item,
        quantity,
      });
    },
  },
};
</script>
