<template>
  <v-card class="h-100 w-100">
    <v-card-title primary-title>
      Products
      <v-spacer></v-spacer>
      <v-btn color="secondary" small :to="{ name: 'products-ListProduct' }">
        View All
      </v-btn>
    </v-card-title>
    <v-card-actions>
      <v-list>
        <v-list two-line>
          <v-list-item
            v-for="product in products"
            :key="product.id"
            color="secondary"
          >
            <v-list-item-avatar>
              <v-img contain v-if="product.imageUrl" :src="product.imageUrl" width="60px" />
            </v-list-item-avatar>
            <v-list-item-content>
              <router-link :to="{
                name: 'products-EditProduct',
                query: { id: product.id,
                }}"
                color="secondary">
                <v-list-item-title>{{ product.name }}</v-list-item-title>
              </router-link>
              <v-list-item-subtitle v-if="product.stockOnHand">
                {{ product.stockOnHand }} available
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-list>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'ProductsCard.vue',
  computed: {
    /** Vuex states
     */
    ...mapState('products', ['products']),
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_products();
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('products', ['LOAD_products', 'DELETE_product']),
    /** Delete Product from the collection
     */
    async remove(product) {
      this.isLoading = true;
      await this.DELETE_product(product.id);
      this.isLoading = false;
    },
  },
};
</script>
