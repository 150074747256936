<!-- eslint-disable max-len -->
<template>
  <svg id="visual" viewBox="0 0 450 900" width="450" height="900" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1">
    <rect x="0" y="0" width="450" height="900" fill="#ffffff"></rect>
    <path
      d="M0 676L15 659.3C30 642.7 60 609.3 90 610.3C120 611.3 150 646.7 180 680.2C210 713.7 240 745.3 270 752.2C300 759 330 741 360 730.8C390 720.7 420 718.3 435 717.2L450 716L450 901L435 901C420 901 390 901 360 901C330 901 300 901 270 901C240 901 210 901 180 901C150 901 120 901 90 901C60 901 30 901 15 901L0 901Z"
      fill="#243B5B" stroke-linecap="round" stroke-linejoin="miter"></path>
  </svg>
</template>

<script>
export default {
  name: 'BackgroundNav',
};
</script>

<style scoped>
svg {
  position: absolute;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  display: block;
  background-color: #ffffff;
}</style>
