<template>
  <v-text-field
    dense
    :prepend-inner-icon="mdiMinus"
    :append-icon="mdiPlus"
    v-model="quantity"
    type="number"
    :min="1"
    solo
    hide-details
    @click:append="incrementQuantity"
    @click:prepend-inner="decrementQuantity"
    class="button-add-to-cart"
  >
  </v-text-field>
</template>

<script>
import {
  mdiPlus, mdiMinus, mdiBasketPlus, mdiCheck, mdiBasketCheck,
} from '@mdi/js';

export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      quantity: 1,
      addedToCart: false,
      showCheck: false,
      mdiPlus,
      mdiMinus,
      mdiBasketPlus,
      mdiBasketCheck,
      mdiCheck,
    };
  },
  watch: {
    value(val) {
      this.quantity = val;
    },
    quantity(val) {
      this.$emit('input', val);
    },
  },
  mounted() {
    this.quantity = this.value;
  },
  methods: {
    incrementQuantity() {
      this.quantity += 1;
    },
    decrementQuantity() {
      if (this.quantity > 1) {
        this.quantity -= 1;
      }
    },
  },
};
</script>
