<template>
  <v-container fluid>
    <v-card>
      <v-card-text>
        <v-skeleton-loader
          :loading="isLoading"
          transition="expand-transition"
          type="article"
        >
          <FormShop
              :Shop="editShop"
              :isSaving="isSaving"
              @save="save"
              @cancel="$router.push({name: from.name })">
            <div v-if="editShop && editShop.id">
              <v-row>
                <v-col cols="12" lg="6">
                  <ShopTimes
                    :shop="editShop"
                    timeType="operating"
                    title="Operating Hours"
                  />
                </v-col>
                <v-col cols="12" lg="6">
                  <ShopTimes
                    :shop="editShop"
                    timeType="delivery"
                    title="Delivery Hours"
                  />
                </v-col>
              </v-row>
              <div class="mt-5" />
            </div>
          </FormShop>
        </v-skeleton-loader>
        <ListProducts />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiPlus } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import ListProducts from '~src/modules/products/src/components/ListProducts.vue';
import FormShop from '../components/FormShop.vue';
import ShopTimes from '../components/ShopTimes.vue';

export default {
  name: 'ShopHome',
  module: 'Suppliers',
  components: {
    FormShop,
    ListProducts,
    ShopTimes,
  },
  meta: {
    breadcrumb:
      [{
        text: 'menu.home',
        to: { name: 'app-Home' },
      }, {
        text: 'menu.shop',
        to: { name: 'suppliers-Shop' },
        disabled: true,
      },
      ],
  },
  mixins: [loading],
  computed: {
    ...mapState('shop', ['shop']),
  },
  data() {
    return {
      /**
      * Icons
      */
      mdiPlus,
      /**
      * Get/Set editing Shop. Default to {}.
      */
      editShop: {},
    };
  },
  async mounted() {
    this.isLoading = true;
    this.editShop = await this.LOAD_shop();
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('shop', ['LOAD_shop', 'UPDATE_shop']),
    /** Delete Shop from the collection
     */
    async remove(shop) {
      this.isLoading = true;
      await this.DELETE_shop(shop.id);
      this.isLoading = false;
    },
    /** Save Shop to the collection
     */
    async save(shop) {
      this.isSaving = true;
      await this.UPDATE_shop(shop);
      this.isSaving = false;
      this.$root.$emit('toast:notify', 'Saved successfully');
    },
  },
};
</script>
