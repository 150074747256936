import { Auth } from '@codehq/aurora-auth';
import axios from 'axios';

const auth = new Auth({
  baseUrl: process.env.VUE_APP_APIURL,
  client_id: process.env.VUE_APP_CLIENT_ID,
  client_secret: process.env.VUE_APP_CLIENT_SECRET,
});
const customActions = {
  async LOAD_shop({ commit }) {
    const token = await auth.getAccessToken();
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    const { data } = await axios.get('api/shop');
    commit('SET_shop', data);
    return data;
  },
  async UPDATE_shop({ commit }, shop) {
    const token = await auth.getAccessToken();
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    const { data } = axios.put('api/shop', shop);
    commit('SET_shop', data);
    return data;
  },
};

export default customActions;
