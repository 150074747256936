<template>
  <div>
    <v-data-table
      data-cy="UserManagement-table"
      :headers="headers"
      :items="items"
      sort-by="email"
      :loading="isLoading"
      :search="search"
      >
      <template v-slot:top>
          <v-toolbar flat>
             <div class="text-h5">
                {{ $t('common.userManagement.title') }}
              </div>
              <slot name="top-left">
              </slot>
              <v-spacer></v-spacer>
              <slot name="top-right">
              </slot>
              <v-text-field
                class="mt-5"
                :prepend-icon="mdiMagnify"
                :label="$t('common.search')"
                v-model="search"
              ></v-text-field>
              <v-btn
                :id="`automation-id-UserManagement-btn-refresh`"
                @click="refresh"
                text
                icon
                color="green"
                class="float-right">
                <v-icon>{{ mdiCached }}</v-icon>
              </v-btn>
          </v-toolbar>
      </template>
      <template v-slot:item.emailConfirmed="{ item }">
        <BoolField v-model="item.emailConfirmed" />
      </template>
      <template v-slot:item.phoneNumberConfirmed="{ item }">
        <BoolField v-model="item.phoneNumberConfirmed" />
      </template>
      <template v-slot:item.twoFactorEnabled="{ item }">
        <BoolField v-model="item.twoFactorEnabled" />
      </template>
      <template v-slot:item.roles="{ item }">
        <span>
          {{ formatRoles(item.roles) }}
        </span>
      </template>
      <template v-slot:item.orgUserRoles="{ item }">
        <span>
          {{ formatOrgs(item.orgUserRoles) }}
        </span>
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn-toggle>
          <v-btn color="default" small @click="editUser(item)">
            {{ $t('common.buttons.edit')}}
          </v-btn>
          <v-btn color="error" small @click="deleteUser(item)">
            {{ $t('common.buttons.delete')}}
          </v-btn>
        </v-btn-toggle>
      </template>
      <template v-slot:no-data>
          <h4>No Users</h4>
      </template>
    </v-data-table>
    <v-layout row justify-center>
      <v-dialog v-model="show" persistent max-width="500px"
      transition="dialog-transition">
        <v-card>
          <v-card-title>
            <span class="headline">User Profile</span>
          </v-card-title>
          <v-card-text>
            <!-- vue slot containing the form -->
        <v-layout
          row
          wrap
          v-if="show"
        >
          <v-form
            v-model="valid"
            lazy-validation
            style="width: 90%"
          >
            <v-text-field
              color="primary"
              disabled=""
              label="Email"
              v-model="user.email"
              placeholder="Email"
            />
            <v-checkbox
              color="primary"
              label="Email Confirmed"
              v-model="user.emailConfirmed"
            />
            <v-text-field
              color="primary"
              label="Phone Number"
              v-model="user.phoneNumber"
            />
            <v-checkbox
              label="Phone Confirmed"
              color="primary"
              v-model="user.phoneNumberConfirmed"
            />
            <h3 class="text-center">User Roles</h3>
            <v-checkbox
              color="primary"
              v-for="role in userRoles"
              :key="`userRoles-${role.id}`"
              v-model="user.roles"
              :label="role.name"
              :value="role"
            />
            <h3 class="text-center">Organisations</h3>
            <v-checkbox
              multiple
              color="primary"
              v-for="role in orgUserRoles"
              :key="`orgUserRole-${role.orgId}`"
              v-model="user.orgUserRoles"
              :label="formatOrg(role)"
              :value="role"
            />
          </v-form>
        </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click.native="show = false">Close</v-btn>
            <v-btn color="success" @click.native="save">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </div>
</template>

<script>
import { map } from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import { mdiCached, mdiMagnify } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import BoolField from '@codehq/aurora-app-core/src/components/BoolField.vue';

export default {
  name: 'UserManagement',
  components: {
    BoolField,
  },
  mixins: [loading],
  data() {
    return {
      mdiCached,
      mdiMagnify,
      headers:
      [
        {
          text: 'Username',
          value: 'username',
        },
        {
          text: 'Email',
          value: 'email',
        },
        {
          text: 'Email Confirmed',
          value: 'emailConfirmed',
        },
        {
          text: 'Organisations',
          value: 'orgUserRoles',
        },
        {
          text: 'Roles',
          value: 'roles',
        },
        {
          text: 'Phone Number',
          value: 'phoneNumber',
        },
        {
          text: 'Phone Number Confirmed',
          value: 'phoneNumberConfirmed',
        },
        {
          text: 'Two Factor Auth Enabled',
          value: 'twoFactorEnabled',
        },
        {
          text: '',
          value: 'action',
        },

      ],
      search: '',
      show: false,
      valid: false, // form validation valid bool
      user: {},
    };
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    ...mapActions('userManagement', ['loadRoles', 'loadUsers', 'removeUser', 'saveUser']),
    ...mapActions('organizations', ['LOAD_allOrganizations']),
    deleteUser(user) {
      this.$confirm(this.$t('common.phrases.confirmDelete')).then((confirm) => {
        if (confirm) {
          this.isLoading = true;
          this.removeUser(user);
          this.isLoading = false;
        }
      });
    },
    editUser(user) {
      this.show = true;
      this.user = user;
    },
    formatRoles(roles) {
      return map(roles, (r) => r.name).join(', ');
    },
    formatOrg(o) {
      return `${this.getOrganizationById(o.orgId)?.name} (${o.role})`;
    },
    formatOrgs(orgs) {
      return map(orgs, (r) => this.formatOrg(r)).join(', ');
    },
    parseRoles(roles) {
      return map(roles, (r) => ({ id: r.id, name: r.name }));
    },
    async refresh() {
      this.isLoading = true;
      await Promise.all([
        (async () => {
          await this.loadRoles();
        })(),
        (async () => {
          await this.loadUsers();
        })(),
        (async () => {
          await this.LOAD_allOrganizations();
        })(),
      ]);
      this.isLoading = false;
    },
    save() {
      this.$log.info('saving user');
      this.isLoading = true;
      this.saveUser(this.user);
      this.show = false;
      this.isLoading = false;
    },
  },
  computed: {
    ...mapState('userManagement', ['roles', 'users']),
    ...mapState('organizations', ['organizations', 'allOrganizations']),
    ...mapGetters('organizations', ['orgUserRoles', 'getOrganizationById']),
    items() {
      return this.users?.map((u) => {
        const result = { ...u };
        result.orgUserRoles = result.orgUserRoles?.map((or) => ({
          orgId: or.orgId,
          role: or.role,
        }));
        return result ?? [];
      });
    },
    userRoles() {
      return this.parseRoles(this.roles);
    },
  },
};
</script>
