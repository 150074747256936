<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-autocomplete
          outlined
          rounded
          item-text="name"
          item-value="id"
          v-model="selectedProduct"
          :search-input.sync="search"
          :items="items"
          :prepend-icon="mdiDatabaseSearch"
          label="Search"
          placeholder="Search"
          clearable
          :loading="isLoading"
          @keydown="onInput"
          @input="onSelected"
        ></v-autocomplete>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from 'axios';
import { mdiDatabaseSearch } from '@mdi/js';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'Search',

  data: () => ({
    mdiDatabaseSearch,
    selectedProduct: null,
    search: '',
    items: [],
    isLoading: false,
  }),

  computed: {
    ...mapState('masterProducts', ['masterProducts']),
  },

  async mounted() {
    this.isLoading = true;
    await this.LOAD_masterProducts();
    this.isLoading = false;
  },

  methods: {
    ...mapActions('masterProducts', ['LOAD_masterProducts']),

    async onInput(evt) {
      const { search } = this;
      console.debug(search);
      if (!search) return;

      if (evt.keyCode === 13) {
        this.$router.push({ name: 'app-Search', query: { search } });
      }

      this.isLoading = true;
      try {
        const response = await axios.get(`/api/search?q=${search}`);
        this.items = response.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    onSelected() {
      console.debug(this.selectedProduct);
    },
  },
};
</script>
