<template>
  <v-list
    nav
    dense
    dark
  >
  <div
        v-for="menuItem in items"
        :key="menuItem.name"
    >
      <v-list-item
        v-if="!menuItem.subLinks"
        class="mb-2"
        color="primary"
        link
        :to="{ name: menuItem.pathName }"
        :data-cy="menuItem.name"
        dark
      >
        <v-list-item-icon>
          <v-icon color="primary" v-if="menuItem.icon">{{ menuItem.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="primary--text">{{ $t(menuItem.name) }}</v-list-item-title>
      </v-list-item>
      <v-list-group
        v-else
        :key="menuItem.name"
        no-action
        :prepend-icon="menuItem.icon"
      >
        <template v-slot:activator>
          <v-list-item-title color="primary">{{ $t(menuItem.name) }}</v-list-item-title>
        </template>

        <v-list-item
            color="primary"
            v-for="subLink in menuItem.subLinks"
            :to="{ name: subLink.pathName }"
            :key="subLink.name"
            link
        >
            <v-list-item-title color="primary">{{ $t(subLink.name) }}</v-list-item-title>
        </v-list-item>
      </v-list-group>
    </div>
    <v-list-item>
        <v-list-item-icon>
          <v-icon color="primary">{{ mdiAccount }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title @click="$root.$emit('auth:logoff')" class="primary--text">
          Log off
        </v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<script>
import { mdiAccount } from '@mdi/js';
import menuItems from '../menuItems';

export default {
  mixins: [menuItems],
  data() {
    return {
      mdiAccount,
    };
  },
  computed: {
    items() {
      const items = this.menuItems;
      return items;
    },
  },
};
</script>

<style scoped>
.v-list-item__content > a {
  justify-content: left;
}
</style>
