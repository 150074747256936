<template>
  <v-row class="text-center">
    <v-col cols="12">
      <v-progress-linear :indeterminate="true"></v-progress-linear>
      Logging you in...
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'Init',
  async mounted() {
    this.$log.info('logged in, loading organizations');
    try {
      const orgs = await this.LOAD_organizations();
      await this.$nextTick();
      await new Promise((r) => setTimeout(r, 1500));
      if (orgs.length === 0) {
        this.$log.info('no organizations, redirecting to home');
        this.$router.push({ name: 'app-Home' });
      } else {
        this.$log.info('organizations loaded, redirecting to vendor home');
        this.$router.push({ name: 'suppliers-VendorHome' });
      }
    } catch (error) {
      this.$log.error(error);
    }
  },
  methods: {
    ...mapActions('organizations', ['LOAD_organizations']),
  },
};
</script>
