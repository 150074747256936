<template>
  <v-list
    dense
    nav
  >
    <v-list-item
      v-for="menuItem in items"
      :key="menuItem.name"
      link
      :to="{ name: menuItem.pathName }"
    >
    <v-list-item-icon>
      <v-icon color="secondary" v-if="menuItem.icon">{{ menuItem.icon }}</v-icon>
    </v-list-item-icon>
    <v-list-item-title>{{ $t(menuItem.name) }}</v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<script>
import menuItems from '../adminMenuItems';

export default {
  mixins: [menuItems],
  computed: {
    items() {
      const items = this.menuItems;
      return items;
    },
  },
};
</script>

<style scoped>
.v-list-item__content > a {
  justify-content: left;
}
</style>
