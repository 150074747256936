export default {
  getMasterProduct: (state) => state.masterProduct,
  getMasterProductsWithShops: (state) => state.masterProducts.map((masterProduct) => {
    const { products } = masterProduct;
    const shops = products.map((product) => ({
      product,
      shop: product.shop,
    }));
    const minPrice = Math.min(...products.map((product) => product.price));
    const maxPrice = Math.max(...products.map((product) => product.price));
    return {
      ...masterProduct, shops, minPrice, maxPrice,
    };
  })
    .filter((masterProduct) => masterProduct.shops.length > 0),
  specials: (state, getters) => getters.getMasterProductsWithShops
    .filter((p) => p.minPrice !== p.maxPrice),
  byCategoryId: (state, getters) => (categoryId) => getters.getMasterProductsWithShops
    .filter((p) => p.categoryId === categoryId),
};
