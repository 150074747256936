<template>
  <v-card>
    <v-card-title primary-title>
      Barcode Scanner
    </v-card-title>
    <v-card-text>
      <StreamBarcodeReader
        @decode="onDecode"
        @loaded="onLoaded"
        @error="onError"
        v-if="scanning"
      />
      <v-text-field
        label="Barcode"
        v-model="barcode"
        solo
      />
      <div class="text-error" v-if="result?.length > 0">
        {{ result }}
      </div>
      <div class="d-flex justify-space-between">
        <v-btn text @click="$emit('close')">
          Cancel
        </v-btn>
        <v-btn
          small
          @click="lookup"
          color="success"
          :loading="isLoading"
        >
          Lookup
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
// eslint-disable-next-line import/no-extraneous-dependencies
import { StreamBarcodeReader } from '@teckel/vue-barcode-reader';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'BarcodeScanner',
  components: {
    StreamBarcodeReader,
  },
  mixins: [loading],
  data() {
    return {
      scanning: true,
      barcode: '',
      result: '',
    };
  },
  methods: {
    ...mapActions('masterProducts', ['LOAD_masterProductByBarcode']),
    onDecode(result) {
      console.log(result);
      this.barcode = result;
      this.scanning = false;
      this.lookup();
    },

    onLoaded() {
      console.log('Barcode reader loaded.');
    },

    onError(error) {
      console.error(error);
      window.alert('error');
      this.$root.$emit('toast:error', error);
    },

    async lookup() {
      this.isLoading = true;
      try {
        const product = await this.LOAD_masterProductByBarcode(this.barcode);
        if (product) {
          this.$router.push({
            name: 'products-ProductPage',
            query: { barcode: this.barcode },
          });
        } else {
          this.result = 'Not product found';
          this.scanning = true;
        }
        this.$root.$emit('toast:success', 'Product found');
      } catch (error) {
        console.error(error);
        window.alert('error');
        this.$root.$emit('toast:error', error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
