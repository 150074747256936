export default {
  SET_organization(state, value) {
    state.organization = value;
  },
  SET_organizations(state, value) {
    state.organizations = value;
  },
  SET_allOrganizations(state, value) {
    state.allOrganizations = value;
  },
};
