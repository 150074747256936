<template>
  <div>
    <v-btn
      @click="isActive = !isActive"
      @mouse-over="isActive = true"
    >
      Categories
    </v-btn>
    <CategoriesMenu v-if="isActive" :categories="formattedCategories" @select="categorySelected" />
  </div>
</template>

<script>
import { mdiChevronRight } from '@mdi/js';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'MenuCategories',
  data() {
    return {
      mdiChevronRight,
      isActive: false,
    };
  },
  computed: {
    ...mapState('categories', ['categories']),
    ...mapGetters('categories', ['organizedCategories']),
    formattedCategories() {
      const map = new Map();

      // First, convert the array to a map for easy lookup
      this.categories.forEach((category) => {
        map.set(category.id, { ...category, children: [] });
      });

      // Now, associate each category with its children
      this.categories.forEach((category) => {
        if (category.parentId) {
          const parent = map.get(category.parentId);
          if (!parent) {
            return;
          }
          parent.children.push(map.get(category.id));
        }
      });

      // Return the root categories
      return Array.from(map.values()).filter((category) => !category.parentId);
    },
  },
  mounted() {
    this.LOAD_categories();
  },
  methods: {
    ...mapActions('categories', ['LOAD_categories']),
    categorySelected(category) {
      this.isActive = false;
      if (this.$route.query.category !== category.id) {
        this.$router.push({ name: 'products-CategoryPage', query: { category: category.id } });
      }
    },
  },
};
</script>
<style scoped>
.categories-menu-title {
  background-color: #243B5B;
  color: white;
  padding: 10px;
}
</style>
