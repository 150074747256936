<template>
  <v-form
    lazy-validation
    v-model="valid"
    @submit.prevent="submit"
    ref="form"
  >
      <v-container class="text-center">
        <v-row>
          <v-col cols="12">
            <v-img contain v-if="localShop.bannerImageUrl" :src="localShop.bannerImageUrl"
              max-height="200px" />
            <ImageUpload data-cy="FormShop-bannerImageUrl" v-model="localShop.bannerImageUrl"
              label="Shop Logo" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-text-field
            v-model="localShop.name"
              label="Name"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
            v-model="localShop.contactNumber"
              label="Contact Number"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
            v-model="localShop.email"
              label="Email"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
            v-model="localShop.contactName"
              label="Contact Name"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-checkbox label="Shop is Active" v-model="localShop.isActive" />
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="localShop.faceBookUrl"
              label="FaceBook Link"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
            v-model="localShop.instagramUrl"
              label="Instagram Link"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
            v-model="localShop.mapUrl"
              label="Google Map Link"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="localShop.addressLine1"
              label="Address Line 1"
            />
            <v-text-field
              v-model="localShop.addressLine2"
              label="Address Line 2"
            />
            <v-text-field
              v-model="localShop.addressLine3"
              label="Address Line 3"
            />
            <v-text-field
              v-model="localShop.city"
              label="City"
            />
          </v-col>
        </v-row>
        <div class="d-flex justify-end mb-6">
          <!-- <v-btn color="default" outlined class="ml-4 btn-cancel" @click="cancel"
            data-cy="FormShop-button-cancel">
            {{ $t('common.buttons.cancel') }}
          </v-btn> -->
          <v-btn small color="success" type="submit" class="ml-4" :loading="isSaving"
            data-cy="FormShop-button-save">
              {{ $t('common.buttons.save') }}
            </v-btn>
        </div>
        <slot></slot>
      </v-container>
  </v-form>
</template>

<script>
export default {
  name: 'FormShop',
  module: 'Suppliers',
  props: {
    /**
    * Set the text box value. Default: undefined,
    */
    Shop: {
      type: Object,
      default: () => {},
    },
    /**
    * Set the saving state. Default: false,
    */
    isSaving: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      /**
      * Hold form validation state
      */
      valid: false,
      /**
      * Local value used for property localShop
      */
      localShop: {},
    };
  },
  watch: {
    Shop() {
      this.localShop = this.Shop;
    },
  },
  created() {
    this.localShop = this.Shop;
  },
  methods: {
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    submit() {
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localShop);
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<docs>
# FormShop example

'''js
<FormShop :Shop="Shop" />
'''
</docs>
