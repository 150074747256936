<template>
  <v-container fluid>
    <v-row class="background justify-center align-center mt-6" no-gutters>
      <v-col cols="12" lg="4" xl="4">
        <v-card flat>
          <div class="text-center">
            <v-icon
              color="primary"
              aria-label="My Account"
              role="img"
              aria-hidden="false"
              size="100px">
              {{ mdiShoppingSearch }}
            </v-icon>
          <div class="text-h2 text-center">
            Wys jou Prys
          </div>
          </div>
          <v-card-text>
            <Login>
              <template v-slot:forgotPassword>
                <ForgotPasswordModal
                  :label="$t('common.forgotPasswordHeading')"
                />
              </template>
            </Login>
          </v-card-text>
          <v-card-text>
            <v-container>
                <v-row class="mt-0" justify="center">
                  <h4 class="mb-5" justify="center">
                    <span>{{ $t('login.or') }}</span>
                  </h4>
                </v-row>
                <v-row class="mt-0" justify="center">
                  <v-btn text class="title" :to="{ name: 'auth-Register' }">
                    {{ $t('login.registerAccount') }}
                  </v-btn>
                </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mdiOpenInNew, mdiShoppingSearch } from '@mdi/js';
import Login from '../components/Login.vue';
import ForgotPasswordModal from '../components/ForgotPasswordModal.vue';

export default {
  name: 'login',
  components: {
    ForgotPasswordModal,
    Login,
  },
  data() {
    return {
      mdiOpenInNew,
      mdiShoppingSearch,
    };
  },
};
</script>
