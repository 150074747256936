<template>
  <v-dialog v-model="dialog" persistent max-width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn text color="primary" style="float: right"
        x-small
            v-bind="attrs"
            v-on="on">
        {{ $t('common.forgotPassword') }}
      </v-btn>
    </template>
    <v-card v-if="isCompleted">
      <v-card-title class="headline">{{ $t('common.phrases.forgotPasswordTitle') }}</v-card-title>
      <v-card-text>
        <p class="h4">{{ $t('common.phrases.forgotPasswordInstructions') }}</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" :loading="isLoading"
          @click.native="finish">
            {{ $t('common.ok') }}
          </v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-else>
      <v-card-title class="headline">{{ $t('common.phrases.passwordReset') }}</v-card-title>
      <v-card-text>
        <v-text-field
          :label="$t('common.email')"
          v-model="localEmail"
          :rules="[rules.required]"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default" text @click.native="dialog = false">
          {{ $t('common.cancel') }}
        </v-btn>
        <v-btn color="primary" :loading="isLoading"
          @click.native="resetPassword">
            {{ $t('common.phrases.resetPassword') }}
          </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import validation from '@codehq/aurora-app-core/src/components/validation';

export default {
  mixins: [validation],
  props: {
    email: {
      type: String,
      default: '',
    },
  },
  watch: {
    email() {
      this.localEmail = this.email;
    },
  },
  data() {
    return {
      dialog: false,
      localEmail: this.email,
      isLoading: false,
      isCompleted: false,
    };
  },
  methods: {
    async resetPassword() {
      this.isLoading = true;
      await this.$auth.forgot(this.localEmail);
      this.isLoading = false;
      this.isCompleted = true;
    },
    finish() {
      this.dialog = false;
      this.isCompleted = false;
    },
  },
};
</script>
