<template>
  <v-container fluid class="pa-0">
    <!-- <Filters /> -->
    <div class="pl-2 pt-2">
      <MenuCategories />
    </div>
    <div v-if="!$vuetify.breakpoint.mobile" class="mt-3" />
    <HeroImage v-if="!$vuetify.breakpoint.mobile">
      <v-container fill-height fluid>
        <v-row>
          <v-col cols="12" md="5" md-offset="1" class="d-flex flex-column justify-space-between">
            <v-card>
              <v-card-text>
                <v-row v-for="(heroSpecial, index) in heroSpecials" :key="heroSpecial.id">
                  <v-col cols="12" md="6" v-if="heroSpecial.masterProduct">
                    <v-img
                      :src="heroSpecial.masterProduct.imageUrl"
                      height="200px"
                      width="350px"
                    >
                      <div v-if="heroSpecial.masterProduct.name.length > 0" class="text-h6">
                        {{ heroSpecial.masterProduct.name }}
                      </div>
                      <div v-else class="text-h6">
                        {{ heroSpecial.masterProduct.description }}
                      </div>
                    </v-img>
                  </v-col>
                  <v-col cols="12" md="6" class="d-flex flex-column justify-space-between">
                    <div style="flex-grow: 1;">
                      <div
                        :class="isMobile ? 'text-h5' : 'text-h4'"
                        class="text-right"
                        style="text-decoration: line-through;">
                        {{ formatCurrency(heroSpecial.price) }}
                      </div>
                      <div
                        :class="isMobile ? 'text-h5' : 'text-h4'"
                        class="text-right">
                        {{ formatCurrency(heroSpecial.salePrice) }}
                      </div>
                    </div>
                    <div class="flex-grow: 0">
                      <ButtonAddToCartVue @add="addToCart($event, heroSpecial)" />
                    </div>
                  </v-col>
                  <v-col v-if="index !== heroSpecials.length - 1" cols="12">
                    <v-divider></v-divider>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </HeroImage>
    <v-dialog
      v-model="dialog"
      max-width="500px"
      transition="dialog-transition"
    >
      <template v-slot:activator="{ on }">
        <v-btn color="primary" dark v-on="on" class="mt-3 ml-2">
          Scan for price
        </v-btn>
      </template>
      <BarcodeScanner @close="dialog = false" />
    </v-dialog>
    <!-- Specials Tiles -->
    <v-row class="mt-3 mb-5 pl-2">
      <v-col v-for="special in specials" :key="special.id" cols="12" sm="2">
        <MasterProductCard :item="special" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import BarcodeScanner from '@/components/BarcodeScanner.vue';
import ButtonAddToCartVue from '@/modules/products/src/components/ButtonAddToCart.vue';
import MenuCategories from '@/components/MenuCategories.vue';
import HeroImage from '@/components/HeroImage2.vue';
import MasterProductCard from '@/modules/products/src/components/MasterProductCard.vue';
import display from '@/mixins/display';

export default {
  name: 'HomePage',
  components: {
    BarcodeScanner,
    ButtonAddToCartVue,
    MenuCategories,
    HeroImage,
    MasterProductCard,
  },
  mixins: [display],
  data() {
    return {
      dialog: false,
      favouriteItems: [
        { id: 1, name: 'Favourite Item 1', price: '$50' },
        { id: 2, name: 'Favourite Item 2', price: '$75' },
      ],
    };
  },
  computed: {
    ...mapState('products', ['products']),
    ...mapGetters('masterProducts', ['getMasterProductsWithShops', 'specials']),
    ...mapState('masterProducts', ['masterProducts']),
    heroSpecials() {
      if (this.isMobile) {
        return this.specials.slice(0, 1);
      }
      return this.specials.slice(0, 2);
    },
    pageSpecials() {
      return this.specials.slice(2);
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    ...mapActions('products', ['LOAD_allProducts']),
    ...mapMutations('basket', ['ADD_basketItem']),
    ...mapActions('masterProducts', ['LOAD_masterProductsExpanded', 'DELETE_masterProduct']),
    addToCart({ quantity }, item) {
      console.debug(item);
      this.ADD_basketItem({
        masterProduct: item,
        quantity,
      });
    },
    async refresh() {
      await this.LOAD_masterProductsExpanded();
    },
  },
};
</script>
