<template>
  <v-card>
    <v-card-text>
      <v-simple-table v-if="basketItems.length > 0" class="mb-4">
        <template v-slot:default>
          <thead>
            <tr>
              <th>
                <h3>Product</h3>
              </th>
              <th>
                <h3>Quantity</h3>
              </th>
              <th v-for="shop in sortedShops" :key="shop.id">
                <div class="d-flex flex-column align-end pa-2">
                  <v-img
                    v-if="shop.bannerImageUrl"
                    height="50"
                    width="90"
                    :src="shop.bannerImageUrl"
                    contain
                  />
                  <h3 v-else>{{ shop.name }}</h3>
                </div>
              </th>
              <!-- <th>
                <h3>Sub Total</h3>
              </th> -->
              <th>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="basketItem in basketItems" :key="basketItem.id">
              <td>
                <h4>{{ basketItem.masterProduct.name }}</h4>
              </td>
              <td>
                <h4>{{ basketItem.quantity }}</h4>
              </td>
              <td v-for="shop in basketItem.shops" :key="shop.id" class="text-right">
                <span v-if="!shop.subTotal" class="error--text">
                  Item not found
                </span>
                <span v-else-if="getColor(shop, basketItem) === 'default'">
                  R {{ shop.subTotal.toFixed(2) }}
                </span>
                <PriceHighlight
                  v-else
                  dense
                  :color="getColor(shop, basketItem)">
                  R {{ shop.subTotal.toFixed(2) }}
                </PriceHighlight>
              </td>
              <td>
                <v-btn color="error" icon
                  @click="REMOVE_basketItem({ masterProduct: basketItem.masterProduct })">
                  <v-icon>{{ mdiTrashCanOutline }}</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="2">
                <h3>Total</h3>
              </td>
              <td v-for="shop in sortedShops" :key="shop.id" class="text-right">
                <PriceHighlight
                  v-if="shop.total === shopLowestPrice"
                  color="success"
                >
                  <span class="text-h6">
                    R {{ shop.total.toFixed(2) }}
                  </span>
                </PriceHighlight>
                <PriceHighlight
                  v-else-if="shop.total === shopHighestPrice"
                  color="error"
                >
                  <span class="text-h6">
                    {{ shop.total.toFixed(2) }}
                  </span>
                </PriceHighlight>
                <div
                  class="text-h6"
                  v-else
                >
                  R {{ shop.total.toFixed(2) }}
                </div>
              </td>
              <td />
            </tr>
          </tfoot>
        </template>
      </v-simple-table>
      <p v-if="shopBasketItems.length === 0" class="ma-5">
        <v-icon small class="primary--text mr-2 mb-1">
          {{ mdiInformation }}
        </v-icon>
        No item in the basket! Please add items to the basket.
      </p>
    </v-card-text>
    <v-card-actions v-if="shopBasketItems.length > 0">
      <v-btn color="default" @click="CLEAR_basketItems">Clear</v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        right
        class="ml-3"
        :to="{
          name: 'products-CheckoutPage',
        }"
        disabled
      >Checkout</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  mdiCartOutline, mdiInformation, mdiTrashCanOutline,
} from '@mdi/js';
import { mapGetters, mapMutations } from 'vuex';
import { sortBy } from 'lodash';
import display from '@/mixins/display';
import PriceHighlight from './PriceHighlight.vue';

export default {
  name: 'ComparisonItems',
  components: {
    PriceHighlight,
  },
  mixins: [display],
  data() {
    return {
      mdiCartOutline,
      mdiInformation,
      mdiTrashCanOutline,
    };
  },
  computed: {
    ...mapGetters('basket', ['shopBasketItems', 'shopLowestPrice', 'shopHighestPrice', 'shops']),
    basketItems() {
      return this.shopBasketItems ?? [];
    },
    isMobile() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    sortedShops() {
      return sortBy(this.shops, 'total');
    },
  },
  methods: {
    ...mapMutations('basket', ['CLEAR_basketItems', 'REMOVE_basketItem', 'UPDATE_basketItem']),
    async updateCartItem(basketItem, product) {
      const { shop } = product;
      console.debug({ basketItem, product, shop });
      this.UPDATE_basketItem({ basketItem, shop, product });
      await this.$nextTick();
      this.$forceUpdate();
    },
    getColor(shop, basketItem) {
      if (shop.subTotal === basketItem.lowestPrice) {
        return 'success';
      }
      if (shop.subTotal > basketItem.highestPrice) {
        return 'error';
      }
      return 'default';
    },
  },
};
</script>
