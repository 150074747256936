<template>
  <v-form
    lazy-validation
    v-model="valid"
    @submit.prevent="submit"
    ref="form"
  >
      <v-container class="text-center">
        <v-row>
          <v-col cols="12">
            <v-select
              filled
              data-cy="FormCategory-parentId"
              :label="$$t('parent')"
              v-model="localCategory.parentId"
              :items="categories"
              item-text="name"
              item-value="id"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              filled
              data-cy="FormCategory-name"
              :label="$$t('name')"
              v-model="localCategory.name"
            />
          </v-col>
        </v-row>
        <div class="d-flex justify-end mb-6">
          <v-btn color="default" outlined class="ml-4 btn-cancel" @click="cancel"
            data-cy="FormCategory-button-cancel">
            {{ $t('common.buttons.cancel') }}
          </v-btn>
          <v-btn color="primary" type="submit" class="ml-4" :loading="isSaving"
            data-cy="FormCategory-button-save">
              {{ $t('common.buttons.save') }}
            </v-btn>
        </div>
      </v-container>
  </v-form>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'FormCategory',
  module: 'Products',
  mixins: [loading],
  props: {
    /**
    * Set the text box value. Default: undefined,
    */
    Category: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      /**
      * Hold form validation state
      */
      valid: false,
      /**
      * Local value used for property localCategory
      */
      localCategory: {},
    };
  },
  computed: {
    ...mapState('categories', ['categories']),
  },
  watch: {
    Category() {
      this.localCategory = this.Category;
    },
  },
  created() {
    this.localCategory = this.Category;
  },
  mounted() {
    this.LOAD_categories();
  },
  methods: {
    ...mapActions('categories', ['LOAD_categories']),
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    submit() {
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localCategory);
        this.isSaving = true;
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<docs>
# FormCategory example

'''js
<FormCategory :Category="Category" />
'''
</docs>
