<template>
  <v-container fluid>
    <v-row class="d-flex align-stretch">
      <v-col
        cols="12" lg="4" xl="4"
      >
        <Quickstart />
      </v-col>
      <v-col
        cols="12" lg="4" xl="4"
      >
        <GraphNewUsers />
      </v-col>
      <v-col
        cols="12" lg="4" xl="4"
      >
        <GraphSales />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mdiHome } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import org from '@codehq/aurora-app-core/src/mixins/org';
import GraphSales from '../components/GraphSales.vue';
import GraphNewUsers from '../components/GraphNewUsers.vue';
import Quickstart from '../components/Quickstart.vue';

export default {
  name: 'VendorHome',
  module: 'Home',
  meta: {
    breadcrumb:
      [{
        text: 'menu.home',
        to: { name: 'app-Home' },
        disabled: false,
      },
      {
        text: 'menu.vendorHome',
        to: { name: 'suppliers-VendorHome' },
        disabled: true,
      },
      ],
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    GraphSales,
    // eslint-disable-next-line vue/no-unused-components
    GraphNewUsers,
    Quickstart,
  },
  mixins: [loading, org],
  data() {
    return {
      mdiHome,
    };
  },
};
</script>
