<template>
  <v-text-field
    v-model="localValue"
    :label="label"
    :hint="hint"
    :rules="rules"
    :min="min"
    :prepend-inner-icon="mdiLock"
    @click:append="() => (mode = !mode)"
    outlined
    :type="mode ? 'password' : 'text'"
  >
    <template v-slot:append>
      <v-btn icon @click="() => (mode = !mode)">
        <v-icon>
          {{ eyeIcon }}
        </v-icon>
      </v-btn>
      <v-btn v-if="showGenerator" icon @click="generatePassword()">
        <v-icon>
          {{ mdiShieldRefresh }}
        </v-icon>
      </v-btn>
    </template>
  </v-text-field>
</template>

<script>
import generator from 'generate-password';

import {
  mdiEye, mdiEyeOff, mdiLock, mdiShieldRefresh,
} from '@mdi/js';
import basicModel from '@codehq/aurora-app-core/src/mixins/basicModel';
/**
 * PasswordField component
 *
 * @component
 */
export default {
  name: 'PasswordField',
  mixins: [basicModel],
  /**
   * The label text of the PasswordField component
   *
   * @prop
   * @type {String}
   */
  props: {
    label: {
      type: String,
      default: '',
    },
    /**
     * The hint text of the PasswordField component
     *
     * @prop
     * @type {String}
     */
    hint: {
      type: String,
      default: '',
    },
    /**
     * Rules that the input will follow
     *
     * @prop
     * @type {Array}
     */
    rules: {
      type: Array,
      default: () => [],
    },
    /**
     * The minimum length of the password
     *
     * @prop
     * @type {Number}
     */
    min: {
      type: Number,
      default: 0,
    },
    /**
     * Holds the showGenerator value, with a default of true
     *
     * @prop
     * @type {Boolean}
     */
    showGenerator: {
      type: Boolean,
      default: true,
    },
  },
  /**
   * Data object of the PasswordField component
   *
   * @data
   *
   */
  data() {
    return {
      /** Icons
       */
      mdiEye,
      mdiEyeOff,
      mdiLock,
      mdiShieldRefresh,
      /** Mode used to control the visibility of the password
      */
      mode: String,
    };
  },
  /**
   * Computed properties of the PasswordField component
   *
   * @computed
   *
   */
  computed: {
    /**
     * Returns the appropriate icon depending on the visibility mode
     *
     * @returns {Object} The icon object
     */
    eyeIcon() {
      return this.mode ? this.mdiEye : this.mdiEyeOff;
    },
  },
  /**
   * Methods of the PasswordField component
   *
   * @methods
   *
   */
  methods: {
    /**
     * Generates a new random password
     */
    generatePassword() {
      this.localValue = generator.generate({
        length: 8,
        numbers: true,
      });
    },
  },
};
</script>
