import {
  mdiHome,
  mdiShopping,
  mdiHomeCity,
  mdiStoreOutline,
} from '@mdi/js';

export default {
  computed: {
    menuItems() {
      return [
        {
          name: 'menu.home', pathName: 'app-Home', icon: mdiHome, topMenu: false,
        },
        {
          name: 'menu.vendorHome', pathName: 'suppliers-VendorHome', icon: mdiHomeCity, topMenu: false,
        },
        {
          name: 'menu.shop', pathName: 'suppliers-ShopHome', icon: mdiShopping, topMenu: false,
        },
        {
          name: 'menu.shops', pathName: 'suppliers-ShopFronts', icon: mdiStoreOutline, topMenu: false,
        },
      ];
    },
  },
};
