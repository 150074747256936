<template>
  <v-app>
    <Background v-if="!isLoggedIn" />
    <AuthService />
    <v-navigation-drawer
      v-if="isLoggedIn"
      v-model="drawer"
      temporary
      app
      class="nav-drawer"
    >
      <BackgroundNav />
      <v-divider />
      <MenuDrawer />
      <v-divider />
      <v-spacer></v-spacer>
    </v-navigation-drawer>

    <v-app-bar
      v-if="isLoggedIn"
      color="#e6eaee"
      dark
      app
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" color="secondary" />
      <router-link :to="{ name: 'app-Home' }">
        <v-icon
          color="primary"
          aria-label="My Account"
          role="img"
          aria-hidden="false"
          size="50px">
          {{ mdiShoppingSearch }}
        </v-icon>
      </router-link>
      <v-spacer></v-spacer>
      <OrganizationMenu v-if="organization && !$vuetify.breakpoint.mobile" />
      <div class="mr-2 ml-2 hidden-sm-and-down">
        <Account />
      </div>
      <MiniCart v-if="!isMobile" />
      <Feedback  />
      <v-menu v-if="isInRole('Administrators')" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="ml-2" text outlined v-bind="attrs" v-on="on">
            <v-icon color="secondary">{{ mdiCog }}</v-icon>
          </v-btn>
        </template>
        <AdminMenuDrawer />
      </v-menu>
    </v-app-bar>

    <v-main>
      <Breadcrumbs />
      <keep-alive :include="[]">
        <v-slide-x-transition>
          <router-view />
        </v-slide-x-transition>
      </keep-alive>
    </v-main>

    <v-footer v-if="isMobile" fixed padless>
      <v-row no-gutters>
        <v-col cols="6">
          <v-btn class="footer-button"
            block color="primary" :to="{ name: 'app-Home' }"
          >
            <v-icon>{{ mdiHome }}</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="6">
          <MiniCartMobile />
        </v-col>
      </v-row>
    </v-footer>
    <v-footer
      v-else
      padless
      dense
      color="primary"
      dark>
      <v-col
        class="text-center text-caption p-0"
        cols="12"
      >
        <div style="font-size: smaller">
          v{{ appVersion }}
        </div>
      </v-col>
    </v-footer>

    <Toasts :text="message" color="success" />
    <Toasts :text="error" color="error" />
    <BasicToasts />
  </v-app>
</template>

<script>
import { components as auroraAppCore, authHub, errorHub } from '@codehq/aurora-app-core/src';
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import {
  mdiHome, mdiCog, mdiPuzzleOutline, mdiShoppingSearch,
} from '@mdi/js';

import Account from './components/Account.vue';
import AuthService from './components/AuthService.vue';
import Background from './components/Background.vue';
import BackgroundNav from './components/BackgroundNav.vue';
import Breadcrumbs from './components/Breadcrumbs.vue';
import Feedback from './components/Feedback.vue';
import MenuDrawer from './components/MenuDrawer.vue';
import MiniCart from './components/MiniCart.vue';
import AdminMenuDrawer from './components/AdminMenuDrawer.vue';
import OrganizationMenu from './components/OrganizationMenu.vue';
import MiniCartMobile from './components/MiniCartMobile.vue';
import menuItems from './menuItems';
import display from './mixins/display';
import pkg from '../package.json';
import './site.scss';

const {
  BasicToasts, Toasts,
} = auroraAppCore;

export default {
  name: 'App',
  components: {
    Account,
    AdminMenuDrawer,
    AuthService,
    BasicToasts,
    Background,
    BackgroundNav,
    // eslint-disable-next-line vue/no-unused-components
    Breadcrumbs,
    Feedback,
    MenuDrawer,
    MiniCart,
    MiniCartMobile,
    OrganizationMenu,
    // OrganizationSelect,
    Toasts,
  },
  mixins: [display, menuItems],
  data() {
    return {
      message: '',
      error: '',
      drawer: false,
      mdiHome,
      mdiCog,
      mdiPuzzleOutline,
      mdiShoppingSearch,
    };
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn', 'isInRole']),
    ...mapState('organizations', ['organization']),
    appVersion() {
      return pkg.version;
    },
    topMenuItems() {
      return this.menuItems.filter((m) => m.topMenu === true);
    },
  },
  created() {
    this.$root.$on('organization.saved', (organization) => {
      this.SET_organization(organization);
      this.$router.push({
        name: 'app-Init',
        params: {
          initial: true,
        },
      });
    });
    errorHub.$on('network.error', () => {
      this.$root.$emit('network.error');
    });
    authHub.$on('user.unauthorized', () => {
      this.$auth.logoff();
      this.$router.push('/auth/login');
    });
    this.$root.$on('toast:error', (err) => {
      this.error = '';
      this.error = err;
    });
    this.$root.$on('auth:login', () => {
      this.$router.push({ name: 'app-Init' });
      this.$root.$emit('toast:notify', 'Logged in successfully');
    });
    this.$root.$on('auth:logoff', () => {
      this.$router.push('/auth/login');
      this.$root.$emit('toast:notify', 'Logged out successfully');
    });
  },
  destroyed() {
    authHub.$off('user.unauthorized');
    this.$root.$off('auth:login');
  },
  methods: {
    ...mapActions('auth', ['LOGOUT_auth', 'INIT_auth']),
    ...mapMutations('organizations', ['SET_organization']),
    async installExtension() {
      this.$confirm('Are you sure you want to install the chrome extension?');
    },
    logout() {
      this.LOGOUT_auth();
      this.$router.push('/auth/login');
    },
  },
};
</script>
