<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="filteredItems"
      class="elevation-1"
      :loading="isLoading"
      sort-by="created"
      sort-desc
    >
    <template v-slot:top>
      <v-toolbar
        flat
      >
      <v-row class="mt-5">
        <v-col cols="3" v-if="showStaffFilter">
          <v-select
            data-cy="ListFeedbackItems-staff"
            filled
            label="Filter Staff"
            :items="users"
            item-text="email"
            item-value="email"
            v-model="selectedStaff"
            multiple
            clearable
          />
        </v-col>
        <v-col cols="3">
          <v-select
            data-cy="ListFeedbackItems-status"
            filled
            label="Filter Status"
            :items="statuses"
            v-model="selectedStatus"
            multiple
            clearable
          />
        </v-col>
        <v-col cols="3">
          <v-select
            data-cy="ListFeedbackItems-modules"
            filled
            label="Filter Module"
            :items="appSections"
            v-model="selectedModule"
            multiple
            clearable
          />
        </v-col>
        <v-col cols="3">
          <v-select
            data-cy="ListFeedbackItems-feedbackTypes"
            filled
            label="Filter Type"
            :items="feedbackTypes"
            v-model="selectedFeedbackType"
            multiple
            clearable
          />
        </v-col>
      </v-row>
      <ExportToExcelButton name="New Business Log" :data="exportItems" />
      </v-toolbar>
    </template>
    <template v-slot:item.created="{ item }">
      {{ formatDateTime(item.created) }}
    </template>
    <template v-slot:item.appSection="props">
      <v-edit-dialog
        large
        :return-value.sync="props.item.appSection"
        @save="save(props.item)"
        @cancel="cancel"
      >
        {{ props.item.appSection }}
        <template v-slot:input>
          <v-select
            :items="appSections"
            v-model="props.item.appSection"
            label="Module"
          ></v-select>
        </template>
      </v-edit-dialog>
    </template>
    <template v-slot:item.feedbackType="props">
      <v-edit-dialog
        large
        :return-value.sync="props.item.feedbackType"
        @save="save(props.item)"
        @cancel="cancel"
      >
        {{ props.item.feedbackType }}
        <template v-slot:input>
          <v-select
            :items="feedbackTypes"
            v-model="props.item.feedbackType"
            label="Type"
          ></v-select>
        </template>
      </v-edit-dialog>
    </template>
    <template v-slot:item.status="props">
      <v-edit-dialog
        large
        :return-value.sync="props.item.status"
        @save="save(props.item)"
        @cancel="cancel"
      >
        {{ props.item.status }}
        <template v-slot:input>
          <v-select
            :items="statuses"
            v-model="props.item.status"
            label="Status"
          ></v-select>
        </template>
      </v-edit-dialog>
    </template>
      <template v-slot:item.createdBy="{ item }">
        <a
          :href="`mailto:${item.createdBy}?subject=${truncate(item.message, 20)}`"
        >
          {{ item.createdBy }}
        </a>
      </template>
      <template v-slot:item.screenshot="{ item }">
        <v-btn
          @click="showScreenshot(item)"
          color="success"
        >
          <v-icon>{{ mdiImage }}</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn x-small color="default" small @click="completeItem(item)">
          Archive
        </v-btn>
      </template>
      <template v-slot:item.actions2="{ item }">
        <v-btn x-small color="error" small @click="remove(item)">
          {{ $t('common.buttons.delete')}}
        </v-btn>
      </template>
    </v-data-table>
      <v-dialog
        v-model="dialog"
        transition="dialog-bottom-transition">
        <v-card elevation="3">
          <v-card-actions>
            <v-btn @click="dialog = false">Close</v-btn>
          </v-card-actions>
          <v-card-text>
            <img v-bind:src="screenshot" />
          </v-card-text>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mdiImage } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import ExportToExcelButton from './ExportToExcelButton.vue';

export default {
  name: 'ListFeedbackItems',
  module: 'common',
  components: {
    ExportToExcelButton,
  },
  props: ['items', 'showStaffFilter'],
  mixins: [loading],
  data() {
    return {
      mdiImage,
      appSections: ['General', 'Contracts'],
      feedbackTypes: ['Bug', 'Feature Request', 'Feedback'],
      dialog: false,
      screenshot: undefined,
      selectedStatus: [],
      selectedStaff: [],
      selectedModule: [],
      selectedFeedbackType: [],
      statuses: [
        'New',
        'Ignored',
        'In Progress',
        'Ready for Review',
        'Completed',
        'On Hold',
      ],
    };
  },
  computed: {
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: 'Status',
          value: 'status',
        },
        // {
        //   text: 'Type',
        //   value: 'feedbackType',
        // },
        // {
        //   text: 'Module',
        //   value: 'appSection',
        // },
        {
          text: this.$$t('message'),
          value: 'message',
        },
        {
          text: this.$$t('created'),
          value: 'created',
        },
        {
          text: this.$$t('createdBy'),
          value: 'createdBy',
        },
        {
          text: this.$$t('screenshot'),
          value: 'screenshot',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
    users() {
      return this.items.map((item) => item.createdBy);
    },
    exportItems() {
      return this.filteredItems.map((item) => ({
        Status: item.status,
        Type: item.feedbackType,
        Module: item.appSection,
        Created: item.created,
        CreatedBy: item.createdBy,
        Message: item.message,
      }));
    },
    filteredItems() {
      const {
        selectedStatus, selectedStaff, selectedModule, selectedFeedbackType,
      } = this;
      let result = [...this.items];
      if (selectedStaff.length > 0) {
        result = result.filter((item) => selectedStaff.includes(item.createdBy));
      }
      if (selectedStatus.length > 0) {
        result = result.filter((item) => selectedStatus.includes(item.status));
      }
      if (selectedModule.length > 0) {
        result = result.filter((item) => selectedModule.includes(item.appSection));
      }
      if (selectedFeedbackType.length > 0) {
        result = result.filter((item) => selectedFeedbackType.includes(item.feedbackType));
      }

      return result;
    },
  },
  methods: {
    ...mapActions('feedbackItems', ['UPDATE_feedbackItem', 'DELETE_feedbackItem', 'LOAD_screenshot']),
    cancel() {
      this.$emit('refresh');
    },
    /**
     * Executes completeItem
     */
    async completeItem(item) {
      this.isLoading = true;
      const updatedItem = { ...item };
      updatedItem.isActive = false;
      await this.UPDATE_feedbackItem(updatedItem);
      this.isLoading = false;
    },
    /**
     * Show modal with screenshot
     */
    async showScreenshot(item) {
      this.isLoading = true;
      this.dialog = true;
      const feedbackItem = await this.LOAD_screenshot(item.id);
      this.screenshot = feedbackItem.screenshot;
      this.isLoading = false;
    },
    /** Delete Supplier from the collection
     */
    async remove(feedbackItem) {
      const result = await this.$confirm('Are you sure you want to delete this item?');
      if (result) {
        this.isLoading = true;
        await this.DELETE_feedbackItem(feedbackItem.id);
        this.isLoading = false;
      }
    },
    truncate(input, len) {
      if (input.length > len) {
        return `${input.substring(0, len)}...`;
      }
      return input;
    },
    async save(item) {
      this.isLoading = true;
      await this.UPDATE_feedbackItem(item);
      this.isLoading = false;
    },
  },
};
</script>
