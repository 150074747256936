<template>
  <v-menu offset-y v-if="isLoggedIn">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="secondary" text outlined v-bind="attrs" v-on="on">
        <v-icon color="secondary">{{ mdiAccount }}</v-icon>
        <div class="hidden-sm-and-down">
          {{username}}
        </div>
        <v-icon color="secondary">{{ mdiChevronDown }}</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item @click="logout">
        <v-list-item-title>{{ $t('common.logoff') }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { mdiAccount, mdiChevronDown } from '@mdi/js';

export default {
  module: 'auth',
  name: 'Account',
  data() {
    return {
      /** icons
       */
      mdiAccount,
      mdiChevronDown,
    };
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn']),
    ...mapState('auth', ['username']),
  },
  methods: {
    ...mapActions('auth', ['LOGOUT']),
    logout() {
      this.$root.$emit('auth:logoff');
      this.$router.push('/');
    },
  },
};
</script>
