<template>
  <v-container>
    <h1>Shops</h1>
    <v-data-iterator
      content-tag="tag"
      row
      wrap
      :items="shops"
      :loading="isLoading"
      hide-default-footer
      :items-per-page="-1"
      no-results-text="No shops found"
      no-data-text="Loading..."
    >
    <template v-slot:default="{ items }">
        <v-row class="d-flex">
          <v-col cols="12" xs="12" sm="6" md="4" lg="3" xl="3"
            class="d-flex align-stretch"
            v-for="(item, index) in items"
            :key="`shop-front-${index}`"
            :data-cy="`stock-item-card-${index}`"
          >
            <v-card style="cursor: pointer;" class="pa-2">
              <v-img
                v-if="item.bannerImageUrl"
                :src="item.bannerImageUrl"
                contain
                height="350px"
                width="300px"
                @click="$router.push({ name: 'suppliers-ShopPage', query: { shop: item.id } })"
              >
              </v-img>
              <div v-else class="master-product-title" style="height: 350px; width: 300px">
                <div class="text-h6">
                  {{ item.name }}
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
  </v-container>
</template>

<script>
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'ShopFronts',
  mixins: [loading],
  data() {
    return {
      pagination: {},
    };
  },
  computed: {
    shops() {
      return this.$store.state.shops.shops;
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.$store.dispatch('shops/LOAD_activeShops');
    this.isLoading = false;
  },
};
</script>
