import { Client } from '@codehq/aurora-net';

const client = new Client({
  baseUrl: process.env.VUE_APP_APIURL,
  client_id: process.env.VUE_APP_CLIENT_ID,
  client_secret: process.env.VUE_APP_CLIENT_SECRET,
  apiClient: 'axios',
  timeout: 30000,
});

export default client;
