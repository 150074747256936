<template>
  <v-form ref="form" style="width: 100%;" @keydown.enter="submit" @submit.prevent="register">
    <v-text-field
      :label="$t('common.businessName')"
      outlined
      v-model="form.businessName"
      :rules="rules.businessName"
      data-cy="register-businessName"
      :prepend-inner-icon="mdiAccount"
      autocomplete="businessName"
    />
    <v-text-field
      :label="$t('common.address')"
      outlined
      v-model="form.address"
      :rules="rules.address"
      data-cy="register-address"
      :prepend-inner-icon="mdiAccount"
      autocomplete="address"
    />
    <v-text-field
      :label="$t('common.contactName')"
      outlined
      v-model="form.contactName"
      :rules="rules.contactName"
      data-cy="register-contactName"
      :prepend-inner-icon="mdiAccount"
      autocomplete="contactName"
    />
    <v-text-field
      :label="$t('common.contactNumber')"
      outlined
      v-model="form.contactNumber"
      :rules="rules.contactNumber"
      data-cy="register-contactNumber"
      :prepend-inner-icon="mdiAccount"
      autocomplete="contactNumber"
    />
    <v-text-field
      :label="$t('common.email')"
      outlined
      v-model="form.email"
      :rules="rules.email"
      data-cy="register-email"
      :prepend-inner-icon="mdiAccount"
      autocomplete="username"
    />
    <PasswordField
      :label="$t('common.password')"
      :rules="rules.password"
      v-model="form.password"
      outlined
      data-cy="register-password"
      autocomplete="new-password"
      class="mb-0"
    />
    <!-- Repeat -->
    <PasswordField
      :prepend-inner-icon="mdiLockOutline"
      :label="$t('common.confirmPassword')"
      :rules="rules.repeat"
      :showGenerator="false"
      v-model="form.repeat"
      outlined
      filled
      autocomplete="new-password"
      data-cy="register-confirm-password"
    />

    <v-alert type="error" v-for="error in errors" :key="error">
      {{ error }}
    </v-alert>
    <ButtonProgress
      height="50"
      class="primary mb-2 rounded-lg text-capitalize d-block"
      style="width: 100%"
      :loading="isLoading"
      type="submit"
      data-cy="register-buttonRegister"
    >
      {{ $t('common.register') }}
    </ButtonProgress>
  </v-form>
</template>

<script>
import { mdiAccount, mdiLock, mdiLockOutline } from '@mdi/js';
import { VAlert, VForm, VTextField } from 'vuetify/lib';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import PasswordField from './PasswordField.vue';

export default {
  name: 'Register',
  mixins: [loading],
  components: {
    PasswordField,
    VAlert,
    VForm,
    VTextField,
  },
  data() {
    return {
      mdiAccount,
      mdiLock,
      mdiLockOutline,
      errors: [],
      form: {
        businessName: '',
        address: '',
        contactName: '',
        contactNumber: '',
        email: '',
        password: '',
        repeat: '',
        errors: '',
      },
      rules: {
        required: [
          (v) => !!v || 'This field is required',
        ],
        businessName: [
          (v) => !!v || 'This field is required',
        ],
        address: [
          (v) => !!v || 'This field is required',
        ],
        contactName: [
          (v) => !!v || 'This field is required',
        ],
        contactNumber: [
          (v) => !!v || 'This field is required',
        ],
        email: [
          (v) => !!v || 'This field is required',
          (v) => /^[A-z0-9\-.]{3}.*@[A-z0-9]*\.[A-z]{2}.*$/gi.test(v) || 'Please provide valid E-Mail',
        ],
        password: [
          (v) => !!v || 'This field is required',
          (v) => v.length > 7 || 'Password should be longer',
        ],
        repeat: [
          (v) => !!v || 'This field is required',
          (v) => v === this.form.password || 'Passwords do not match',
        ],
      },
    };
  },
  methods: {
    async register() {
      if (this.$refs.form.validate()) {
        try {
          this.isLoading = true;
          const {
            businessName, address, contactName, contactNumber, email, password,
          } = this.form;
          this.$log.info(`Registering ${email}`);
          await this.$auth.register(email, email, password);
          // businessName, address, contactName, contactNumber, email, password);
          this.$root.$emit('auth:register', {
            businessName, address, contactName, contactNumber, username: email, email,
          });
          this.$log.info(`Logging on ${email}`);
          await this.$auth.login(email, password);
          this.$log.info(`Signing up as Vendor: ${businessName} (${email})`);
          const result = this.$http.post('/suppliers/join', {
            businessName,
            address,
            contactName,
            contactNumber,
            email,
          });
          this.$log.info('Signed up', result);
          this.$log.info(`Logging off ${email}`);
          this.$auth.logoff();
          this.$log.info(`Logging on ${email}`);
          await this.$auth.login(email, password);
          this.$root.$emit('auth:login');
        } catch (error) {
          this.errors = error.response.data.modelState[''];
          this.$log.error(error.response.data.error_description);
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>
