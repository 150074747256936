<template>
  <v-container fluid>
  <v-data-table
    :headers="headers"
    :items="suppliers"
    class="elevation-1"
    :loading="isLoading"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-btn
          color="success"
          data-cy="Supplier-button-new"
          @click="$router.push({
            name: 'suppliers-EditSupplier'
          })"
        >
          <v-icon class="mr-2">
            {{ mdiPlus }}
          </v-icon>
          {{ $t('common.buttons.new') }}
        </v-btn>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <TableBasicButtons :editLink="{
          name: 'suppliers-EditSupplier',
          query: {id: item.id}
        }"
        @delete="remove(item)"
      />
    </template>
  </v-data-table>
</v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiPlus } from '@mdi/js';
import TableBasicButtons from '@codehq/aurora-app-core/src/components/TableBasicButtons.vue';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'ListSupplier',
  module: 'Suppliers',
  components: {
    TableBasicButtons,
  },
  mixins: [loading],
  meta: {
    breadcrumb:
      [{
        text: 'menu.home',
        to: { name: 'app-Home' },
      },
      {
        text: 'menu.suppliers',
        to: { name: 'suppliers-ListSupplier' },
        disabled: true,
      },
      ],
  },
  data() {
    return {
      /**
      * Icons
      */
      mdiPlus,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('suppliers', ['suppliers']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: this.$$t('name'),
          value: 'name',
        },
        {
          text: this.$$t('email'),
          value: 'email',
        },
        {
          text: this.$$t('telephoneNumber'),
          value: 'telephoneNumber',
        },
        {
          text: this.$$t('contact'),
          value: 'contact',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_suppliers();
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('suppliers', ['LOAD_suppliers', 'DELETE_supplier']),
    /** Delete Supplier from the collection
     */
    async remove(supplier) {
      this.isLoading = true;
      await this.DELETE_supplier(supplier.id);
      this.isLoading = false;
    },
  },
};
</script>

<docs>
# Supplier example

'''js
<Supplier />
'''
</docs>
