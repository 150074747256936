<template>
  <div class="pa-3">
    <MenuCategories />
    <v-card class="mt-5">
      <v-card-text>

      <!-- Specials Tiles -->
      <v-row class="mt-3 mb-5 pl-2" v-if="getMasterProductsWithShops.length > 0">
        <v-col v-for="product in getMasterProductsWithShops" :key="product.id" cols="12" sm="3">
          <MasterProductCard :item="product" />
        </v-col>
      </v-row>
      <v-row v-else class="mt-3 mb-5 pl-2">
        <v-col cols="12">
          No products found
        </v-col>
      </v-row>

      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import MenuCategories from '@/components/MenuCategories.vue';
import MasterProductCard from '../components/MasterProductCard.vue';

export default {
  name: 'CategoryPage',
  components: {
    MenuCategories,
    MasterProductCard,
  },
  meta: {
    breadcrumb:
      [{
        text: 'menu.home',
        to: { name: 'app-Home' },
      }],
  },
  data() {
    return {
      barcode: this.$route.query.barcode,
      products: [],
    };
  },
  computed: {
    ...mapState('categories', ['category']),
    ...mapGetters('masterProducts', ['getMasterProductsWithShops', 'specials']),
    categoryProducts() {
      return this.getMasterProductsWithShops
        .filter((p) => p.categoryId === this.categoryId);
    },
  },
  watch: {
    '$route.query.category': {
      immediate: true,
      handler(categoryId) {
        this.categoryId = categoryId;
        this.refresh();
      },
    },
  },
  mounted() {
    this.refresh();
    console.debug(this.$route);
  },
  methods: {
    ...mapActions('masterProducts', ['LOAD_masterProductByBarcode']),
    async refresh() {
      await this.LOAD_masterProductByBarcode(this.barcode);
      this.$root.$emit('update-breadcrumbs', [{
        text: 'menu.home',
        to: { name: 'app-Home' },
      }, {
        text: this.barcode,
        to: { name: 'products-ProductPage', query: { barcode: this.barcode } },
      }]);
    },
  },
};
</script>
