import components from './components';
import config from './config';
import locales from './locales';
import views from './views';

export { components };
export { locales };
export { views };

export default {
  config, components, locales, views,
};
