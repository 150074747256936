<template>
<v-row>
  <v-col cols="12">
    <v-card
      v-for="shopItem in shopItems"
      :key="shopItem.id"
      class="mb-5"
    >
      <v-card-title primary-title>
        {{ shopItem.name }}
      </v-card-title>
      <v-card-text>
        <v-simple-table class="mb-4">
          <template v-slot:default>
            <thead>
              <tr>
                <th>
                  <h3>Product</h3>
                </th>
                <th>
                  <h3>Quantity</h3>
                </th>
                <th>
                  <h3>Price</h3>
                </th>
                <!-- <th>
                  <h3>Sub Total</h3>
                </th> -->
                <th>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(basketItem, index) in shopItem.items" :key="index">
                <td>
                  <h4>{{ basketItem.masterProduct.name }}</h4>
                </td>
                <td>
                  <h4>{{ basketItem.quantity }}</h4>
                </td>
                <td class="price-col">
                    <h4 v-if="basketItem.price">
                      {{formatCurrency(basketItem.price)}}
                    </h4>
                </td>
                <!-- <td>
                    <h4 v-if="basketItem.subTotal" style="white-space: nowrap;">
                      {{formatCurrency(basketItem.subTotal)}}
                    </h4>
                </td> -->
                <td>
                  <v-btn color="error" icon
                    @click="REMOVE_basketItem({ masterProduct: basketItem })">
                    <v-icon>{{ mdiTrashCanOutline }}</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="4">
                  <div class="text-h5 text-right">
                    Total: {{ shopItem.subTotal }}
                  </div>
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-col>
</v-row>
</template>

<script>
import {
  mdiCartOutline, mdiInformation, mdiTrashCanOutline,
} from '@mdi/js';
import { mapGetters, mapMutations } from 'vuex';
import display from '@/mixins/display';

export default {
  name: 'ShopItems',
  mixins: [display],
  data() {
    return {
      mdiCartOutline,
      mdiInformation,
      mdiTrashCanOutline,
    };
  },
  computed: {
    ...mapGetters('basket', ['shopItems']),
  },
  methods: {
    ...mapMutations('basket', ['CLEAR_basketItems', 'REMOVE_basketItem', 'UPDATE_basketItem']),
    updateCartItem(basketItem, product) {
      const { shop } = product;
      console.debug({ basketItem, product, shop });
      this.UPDATE_basketItem({ basketItem, shop: product });
    },
  },
};
</script>
