<template>
  <EditPage :title="mode === 'edit' ? 'Edit Product' : 'New Product'">
    <v-skeleton-loader
      :loading="isLoading"
      transition="expand-transition"
      type="article"
    >
      <FormProduct
        :Product="editProduct"
        :isSaving="isSaving"
        @save="save"
        @cancel="$router.push({name: from.name })" />
    </v-skeleton-loader>
  </EditPage>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import EditPage from '@/layouts/EditPage.vue';
import FormProduct from '../components/FormProduct.vue';

export default {
  components: {
    EditPage,
    FormProduct,
  },
  name: 'EditProduct',
  module: 'Products',
  mixins: [loading],
  meta: {
    redirectOnRefresh: { name: 'products-ListProduct' },
    breadcrumb:
      [{
        text: 'menu.home',
        to: { name: 'app-Home' },
      },
      {
        text: 'menu.products',
        to: { name: 'products-ListProduct' },
      },
      {
        text: 'menu.edit',
      },
      ],
  },
  data() {
    return {
      /**
      * Get/Set editing Product. Default to {}.
      */
      editProduct: {},
      /**
       * Query Id for Product
       */
      productId: this.$route?.query.id,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('products', ['products', 'product']),
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.productId) {
        return 'edit';
      }
      return 'add';
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_masterProducts();
    if (this.mode === 'edit') {
      await this.LOAD_product(this.productId);
      this.editProduct = this.product;
    }
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('products', ['LOAD_product', 'CREATE_product', 'UPDATE_product']),
    ...mapActions('masterProducts', ['LOAD_masterProducts']),
    /** Method used to save the item
     */
    async save(product) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_product(product);
      } else {
        await this.UPDATE_product(product);
      }
      this.isSaving = false;
      this.$router.push({ name: this.from.name });
    },
  },
};
</script>

<docs>
# EditProduct example

'''js
<EditProduct :Product="Product" />
'''
</docs>
