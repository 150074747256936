import { uniq } from 'lodash';

export default {
  basketItems: (state) => state.basketItems.map((item) => {
    const subTotal = (item.salePrice ?? item.price) * item.quantity;
    return {
      ...item,
      subTotal,
    };
  }),
  shopBasketItems: (state, getters) => {
    const newBasketItems = [];
    const { shops } = getters;
    state.basketItems.forEach((basketItem) => {
      const { masterProduct, quantity } = basketItem;
      const newBasketItem = { shops: [], masterProduct, quantity };
      shops.forEach((shop) => {
        const shopBasketItem = basketItem.masterProduct.shops
          .find((item) => item.shop?.id === shop.id);
        if (shopBasketItem) {
          const { product } = shopBasketItem;
          const price = product.salePrice ?? product.price;
          const subTotal = price * quantity;
          console.debug(shop);
          newBasketItem.shops.push({
            ...shop,
            product,
            quantity: newBasketItem.quantity,
            price,
            subTotal,
          });
        } else {
          newBasketItem.shops.push({
            ...shop,
            shopProduct: null,
            product: basketItem.product,
            quantity: newBasketItem.quantity,
            price: basketItem.price,
            subTotal: basketItem.subTotal,
          });
        }
      });
      const subTotals = newBasketItem.shops
        .filter((item) => item.subTotal)
        .map((item) => item.subTotal);
      const lowestPrice = Math.min(...subTotals);
      const highestPrice = Math.max(...subTotals);
      newBasketItem.lowestPrice = lowestPrice;
      newBasketItem.highestPrice = highestPrice;
      newBasketItem.subTotals = subTotals;
      newBasketItems.push(newBasketItem);
    });
    return newBasketItems;
  },
  shops: (state) => {
    const basketShops = state.basketItems.map((item) => item.masterProduct.shops).flat();
    const shopIds = uniq(basketShops.map((item) => item.shop?.id));
    const shops = shopIds.map((shopId) => {
      const r = basketShops.find((item) => item.shop?.id === shopId);
      if (!r.shop) {
        return {
          id: null,
          name: null,
          bannerImageUrl: null,
          products: [],
          total: 0,
        };
      }
      const { id, name, bannerImageUrl } = r.shop;
      const products = basketShops.filter((item) => item.shop?.id === shopId)
        .map((item) => item.product);
      const total = products.reduce((acc, item) => acc + (item.salePrice ?? item.price), 0);
      return {
        id,
        name,
        bannerImageUrl,
        products,
        total,
      };
    });
    return shops
      .filter((s) => s.id);
  },
  shopLowestPrice: (state, getters) => {
    const { shops } = getters;
    const subTotals = shops.map((item) => item.total);
    return Math.min(...subTotals);
  },
  shopHighestPrice: (state, getters) => {
    const { shops } = getters;
    const subTotals = shops.map((item) => item.total);
    return Math.max(...subTotals);
  },
  subTotal: (state, getters) => getters.basketItems.reduce((acc, item) => acc + item.subTotal, 0)
    .toFixed(2),
  total: (state, getters) => getters.subTotal,
  totalItems: (state, getters) => getters.basketItems.reduce((acc, item) => acc + item.quantity, 0),
};
