<template>
  <v-card>
    <v-card-text>
      <v-simple-table v-if="basketItems.length > 0" class="mb-4">
        <template v-slot:default>
          <thead>
            <tr>
              <th>
                <h3>Product</h3>
              </th>
              <th>
                <h3>Supplied By</h3>
              </th>
              <th v-if="!isMobile">
                <h3>Quantity</h3>
              </th>
              <th v-if="!isMobile">
                <h3>Price</h3>
              </th>
              <!-- <th>
                <h3>Sub Total</h3>
              </th> -->
              <th>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="basketItem in basketItems" :key="basketItem.id">
              <td>
                <h4>{{ basketItem.masterProduct.name }}</h4>
              </td>
              <td>
                <v-select
                  :items="basketItem.masterProduct.products"
                  :item-text="p => `${p.shop.name} - ${formatCurrency(p.price)}`"
                  dense
                  return-object
                  label="Select Shop"
                  class="mt-5"
                  outlined
                  @change="updateCartItem(basketItem, $event)"
                />
                <!-- <h4>{{ basket.product.shop.name }}</h4>
                <v-img :src="basket.product.shop.logoUrl" width="50" height="50" /> -->
              </td>
              <td v-if="!isMobile">
                <h4>{{ basketItem.quantity }}</h4>
              </td>
              <td v-if="!isMobile">
                  <h4 v-if="basketItem.price">
                    {{formatCurrency(basketItem.price)}}
                  </h4>
              </td>
              <!-- <td>
                  <h4 v-if="basketItem.subTotal" style="white-space: nowrap;">
                    {{formatCurrency(basketItem.subTotal)}}
                  </h4>
              </td> -->
              <td>
                <v-btn color="error" icon
                  @click="REMOVE_basketItem({ masterProduct: basketItem })">
                  <v-icon>{{ mdiTrashCanOutline }}</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <p v-if="basketItems.length === 0" class="ma-5">
        <v-icon small class="primary--text mr-2 mb-1">
          {{ mdiInformation }}
        </v-icon>
        No item in the basket! Please add items to the basket.
      </p>
    </v-card-text>
    <v-card-actions v-if="basketItems.length > 0">
      <v-btn color="default" @click="CLEAR_basketItems">Clear</v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        right
        class="ml-3"
        :to="{
          name: 'products-CheckoutPage',
        }"
      >Checkout</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  mdiCartOutline, mdiInformation, mdiTrashCanOutline,
} from '@mdi/js';
import { mapGetters, mapMutations } from 'vuex';
import display from '@/mixins/display';

export default {
  name: 'CartItems',
  mixins: [display],
  data() {
    return {
      mdiCartOutline,
      mdiInformation,
      mdiTrashCanOutline,
    };
  },
  computed: {
    ...mapGetters('basket', ['basketItems', 'subTotal', 'totalItems', 'shops']),
    isMobile() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
  },
  methods: {
    ...mapMutations('basket', ['CLEAR_basketItems', 'REMOVE_basketItem', 'UPDATE_basketItem']),
    async updateCartItem(basketItem, product) {
      const { shop } = product;
      console.debug({ basketItem, product, shop });
      this.UPDATE_basketItem({ basketItem, shop, product });
      await this.$nextTick();
      this.$forceUpdate();
    },
  },
};
</script>
