<template>
  <v-btn
    color="secondary"
    class="footer-button"
    data-cy="buttonCart"
    block
    :to="{
        name: 'products-CheckoutPage',
      }"
    >
    <v-badge
      color="primary"
      left
      transition="slide-x-transition"
    >
      <template v-slot:badge>
        {{ totalItems }}
      </template>
      <v-icon color="black" class="mr-1" size="26"> {{ mdiCartOutline }}</v-icon>
    </v-badge>
  </v-btn>
</template>

<script>
import {
  mdiCartOutline, mdiCloseCircle, mdiTrashCanOutline,
} from '@mdi/js';
import { mapGetters, mapMutations } from 'vuex';
import display from '@/mixins/display';

export default {
  name: 'MiniCart',
  mixins: [display],
  data() {
    return {
      dialog: false,
      mdiCartOutline,
      mdiCloseCircle,
      mdiTrashCanOutline,
    };
  },
  computed: {
    ...mapGetters('basket', ['basketItems', 'subTotal', 'totalItems']),
  },
  methods: {
    ...mapMutations('basket', ['CLEAR_basketItems', 'REMOVE_basketItem']),
  },
};
</script>
