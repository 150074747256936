import { Auth } from '@codehq/aurora-auth';
import { odata } from '../../conf';
import Actions from '../../odata/actions';

const auth = new Auth({
  baseUrl: process.env.VUE_APP_APIURL,
  client_id: process.env.VUE_APP_CLIENT_ID,
  client_secret: process.env.VUE_APP_CLIENT_SECRET,
});

const actions = new Actions('shopTime', 'shopTimes');

const customActions = {
  async LOAD_shopTimes({ commit, rootGetters }, {
    shopId, timeType,
  }) {
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('shopTimes')
      .query({ $filter: `IsDeleted eq false AND ShopId eq ${shopId} AND TimeType eq '${timeType}'` });
    commit('SET_shopTimes', data);
    return data;
  },
};

export default { ...actions, ...customActions };
