export default {
  getCategory: (state) => state.category,
  organizedCategories: (state) => {
    const organizeCategories = (categories, parentId) => categories
      .filter((category) => category.parentId === parentId)
      .map((category) => ({
        ...category,
        children: organizeCategories(categories, category.id),
      }));

    return organizeCategories(state.categories, null);
  },
};
