var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"loading":_vm.isLoading}},[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v(" Search results for "),_c('b',[_vm._v(_vm._s(_vm.search))])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"clearable":"","items":_vm.brands,"item-text":"name","item-value":"id","label":"Brand"},model:{value:(_vm.selectedBrand),callback:function ($$v) {_vm.selectedBrand=$$v},expression:"selectedBrand"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"clearable":"","item-text":"name","item-value":"id","items":_vm.categories,"label":"Category"},model:{value:(_vm.selectedCategory),callback:function ($$v) {_vm.selectedCategory=$$v},expression:"selectedCategory"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"items":_vm.sizes,"label":"Size","clearable":""},model:{value:(_vm.selectedSize),callback:function ($$v) {_vm.selectedSize=$$v},expression:"selectedSize"}})],1),_c('v-col',{attrs:{"cols":"3"}})],1),_c('v-data-iterator',{attrs:{"items":_vm.filteredProducts,"loading":_vm.loading,"items-per-page":_vm.itemsPerPage,"footer-props":{
          itemsPerPageOptions: [10, 25, 50, 100],
          showFirstLastPage: true,
          showCurrentPage: true,
          showPageSize: true,
          pageText: 'Page {0} of {1}'
        }},scopedSlots:_vm._u([{key:"header",fn:function(){return undefined},proxy:true},{key:"default",fn:function(props){return [_c('v-row',_vm._l((props.items),function(item){return _c('v-col',{key:item.id,attrs:{"cols":"3"}},[_c('v-card',{staticStyle:{"width":"100%","height":"100%"}},[(item.brand)?_c('v-img',{attrs:{"src":item.brand.imageUrl,"height":"150","contain":""}}):_vm._e(),_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('v-img',{attrs:{"src":item.imageUrl,"height":"300","stretch":""}}),_c('v-card-text',[_c('p',[_vm._v(_vm._s(item.description))]),_c('p',[_vm._v("Size: "+_vm._s(item.size))]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',_vm._l((item.products),function(p,index){return _c('v-col',{key:index,staticClass:"d-flex align-stretch supplier-col",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"supplier-card",attrs:{"elevation":"0"}},[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v(" Supplied By ")]),_c('v-card-text',[_c('v-img',{attrs:{"contain":"","height":"75","src":p.shop.bannerImageUrl}}),_c('h4',{staticClass:"mt-3"},[_vm._v("Tel: "),_c('b',[_vm._v(_vm._s(p.shop.contactNumber))])]),_c('h4',[_vm._v("Operating Hours: "),_c('b',[_vm._v(_vm._s(p.shop.operatingHours))])]),_c('h4',[_vm._v("Price: "),_c('b',[_vm._v("R"+_vm._s(p.price))])]),_c('h4',[_vm._v("Available: "),_c('b',[_vm._v(_vm._s(p.stockOnHand))])])],1)],1)],1)}),1)],1)],1)],1)],1)],1)}),1)]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }