<template>
  <v-card v-if="item" class="h-100 w-100">
    <div v-if="specialPercentage > 0" class="master-product-special-percentage">
      {{ specialPercentage }}% off
    </div>
    <v-card-text class="h-100 d-flex flex-column">
      <v-row>
        <v-col cols="12">
          <v-img
            class="d-flex flex-column align-end master-product-image"
            :src="item.masterProduct.imageUrl"
            stretch
            height="350px"
            width="300px"
          >
            <div class="master-product-title">
              <div class="text-h6">
                {{ item.masterProduct.name }}
              </div>
            </div>
          </v-img>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="d-flex flex-column justify-end">
          <div
            v-if="item.salePrice && item.salePrice > 0"
            :class="isMobile ? 'text-h6' : 'text-h5'"
            class="text-right"
            style="text-decoration: line-through;">
            {{ formatCurrency(item.price) }}
          </div>
          <div
            :class="isMobile ? 'text-h4' : 'text-h5'"
            class="text-right">
            {{ formatCurrency(item.salePrice ?? item.price) }}
          </div>
          <div class="d-flex align-start justify-space-between">
            <v-spacer></v-spacer>
            <ButtonAddToCart @add="addToCart($event, item.masterProduct)" />
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapMutations } from 'vuex';
import display from '@/mixins/display';
import ButtonAddToCart from './ButtonAddToCart.vue';

export default {
  name: 'ProductCard',
  mixins: [display],
  props: {
    /**
         * Set the product item. Default: undefined,
         */
    item: {
      type: Object,
      default: undefined,
    },
  },
  components: { ButtonAddToCart },
  computed: {
    specialPercentage() {
      const { item } = this;
      const { price, salePrice } = item;
      if (salePrice && salePrice > 0) {
        const percentage = Math.round((1 - (salePrice / price)) * 100);
        return percentage;
      }
      return 0;
    },
  },
  methods: {
    ...mapMutations('basket', ['ADD_basketItem']),
    addToCart({ quantity }) {
      const { item } = this;
      this.ADD_basketItem({
        masterProduct: item,
        quantity,
      });
    },
  },
};
</script>
