<template>
  <ul class="category-list">
    <li v-for="category in categories" :key="category.id">
      <div @mouseenter="expand(category)">
        <span @click="select(category)">{{ category.name }}</span>
        <span v-if="hasChildren(category) && !isExpanded(category)">[+]</span>
        <span v-else-if="hasChildren(category)">[-]</span>
      </div>
      <div @mouseleave="collapse(category)">
        <CategoriesMenu
          v-if="hasChildren(category) && isExpanded(category)"
          @select="$emit('select', $event)"
          :categories="category.children"
          :collapsed="collapsed"
        />
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'CategoriesMenu',
  props: {
    categories: Array,
    collapsed: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      localExpanded: {},
    };
  },
  methods: {
    expand(category) {
      this.$set(this.localExpanded, category.id, true);
    },
    collapse(category) {
      this.$set(this.localExpanded, category.id, false);
    },
    isExpanded(category) {
      return this.localExpanded[category.id];
    },
    hasChildren(category) {
      return category.children && category.children.length > 0;
    },
    select(category) {
      this.$emit('select', category);
    },
  },
};
</script>

<style>
.category-list {
  list-style-type: none;
  padding: 0;
  background-color: #202020;
  color: white;
  border-radius: 4px;
  max-width: 25vw;
}

@media (max-width: 768px) {
  .category-list {
    max-width: 90vw;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: none;
}

.category-list.show {
  z-index: 1000;
}

.category-list.show + .overlay {
  display: block;
}
.category-list > li {
  cursor: pointer;
  padding: 5px;
  margin: 5px 0;
  user-select: none;
}
.categories-menu {
  display: none;
}
.category-list > li:hover .categories-menu {
  display: block;
}
</style>
