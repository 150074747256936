import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('feedbackItem', 'feedbackItems');

const customActions = {
  async LOAD_feedbackItems({ commit, rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('feedbackItems')
      .query({
        $filter: 'IsDeleted eq false',
        $select: 'id, status, message, created, createdBy, isActive',
      });
    commit('SET_feedbackItems', data);
    return data;
  },
  async LOAD_screenshot({ rootGetters }, id) {
    const data = await odata.o(rootGetters)
      .get(`feedbackItems(${id})`)
      .query({
        $select: 'screenshot',
      });
    return data;
  },
};

export default { ...actions, ...customActions };
