<template>
  <v-skeleton-loader
    :loading="isLoading"
    transition="expand-transition"
    type="article"
  >
    <FormShop
      :Shop="editShop"
      :isSaving="isSaving"
      @save="save"
      @cancel="$router.push({name: from.name })" />
  </v-skeleton-loader>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormShop from '../components/FormShop.vue';

export default {
  components: {
    FormShop,
  },
  name: 'EditShop',
  module: 'Suppliers',
  mixins: [loading],
  data() {
    return {
      /**
      * Get/Set editing Shop. Default to {}.
      */
      editShop: {},
      /**
       * Query Id for Shop
       */
      shopId: this.$route?.query.id,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('shops', ['shops', 'shop']),
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.shopId) {
        return 'edit';
      }
      return 'add';
    },
  },
  async mounted() {
    this.isLoading = true;
    if (this.mode === 'edit') {
      await this.LOAD_shop(this.shopId);
      this.editShop = this.shop;
    }
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('shops', ['LOAD_shop', 'CREATE_shop', 'UPDATE_shop']),
    /** Method used to save the item
     */
    async save(shop) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_shop(shop);
      } else {
        await this.UPDATE_shop(shop);
      }
      this.isSaving = false;
      this.$router.push({ name: 'shop-ListShop' });
    },
  },
};
</script>

<docs>
# EditShop example

'''js
<EditShop :Shop="Shop" />
'''
</docs>
