import store from '../store';

export default (to, from, next) => {
  const isLoggedIn = store.getters['auth/isLoggedIn'];
  if (to.meta.requiresAuth) {
    // If user has a token
    if (isLoggedIn) {
      // We allow to access page
      next(true);
    } else {
      // Else we redirect to login
      next('/auth/login');
    }
  } else {
    // Else if route hasn't any protection we allow to access page
    next(true);
  }
};
