import Vue from 'vue';
import VueRouter from 'vue-router';
import { kebabCase } from 'case-anything';

import appViews from '../views';
import guards from './guards';
import modules from '../modules';

const Init = () => import(/* webpackChunkName: "route-Init" */ '../views/Init.vue');
const Search = () => import(/* webpackChunkName: "route-Search" */ '../views/SearchView.vue');
const SearchResult = () => import(/* webpackChunkName: "route-SearchResult" */ '../views/SearchResult.vue');
const Login = () => import(/* webpackChunkName: "route-Login" */ '../views/Login.vue');
const Register = () => import(/* webpackChunkName: "route-Register" */ '../views/Register.vue');

Vue.use(VueRouter);

const mapViews = (prefix, views) => {
  const viewsArr = Object.keys(views)
    .filter((v) => views[v].name !== 'Home')
    .map((v) => ({
      path: `/${prefix}/${kebabCase(v)}`,
      name: `${prefix}-${v}`,
      component: views[v],
      meta: views[v].meta,
    }));
  return viewsArr;
};

const appRoutes = [
  {
    path: '/auth/login',
    alias: '/login',
    name: 'auth-Login',
    component: Login,
  },
  {
    path: '/auth/register',
    name: 'auth-Register',
    alias: '/register',
    component: Register,
  },
  {
    path: '/init',
    name: 'app-Init',
    component: Init,
  },
  {
    path: '/admin/users',
    name: 'admin-Users',
    component: appViews.UserManagementHome,
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/feedback-items',
    name: 'admin-FeedbackItems',
    component: appViews.FeedbackItems,
    meta: { requiresAuth: true },
  },
  {
    path: '/home',
    alias: '/',
    name: 'app-Home',
    component: appViews.HomePage,
    meta: { requiresAuth: true, requiresOrg: true },
  },
  {
    path: '/search/result',
    name: 'app-SearchResult',
    component: SearchResult,
    meta: { requiresAuth: true },
  },
  {
    path: '/search',
    name: 'app-Search',
    component: Search,
    meta: { requiresAuth: true },
  },
];

const moduleRoutes = modules.map((m) => mapViews(m.config.path, m.views)).flat();
const routes = [...appRoutes, ...moduleRoutes];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach(guards);

export { routes };
export { router };
