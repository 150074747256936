import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('product', 'products');

const customActions = {
  async LOAD_allProducts({ commit }) {
    const { data } = await this.$http.get('api/search/products');
    commit('SET_products', data);
    return data;
  },
  async LOAD_products({ commit, rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('products')
      .query({ $expand: 'masterProduct,brand' });
    commit('SET_products', data);
    return data;
  },
  async LOAD_productsByCategoryId({ rootGetters }, categoryId) {
    const data = await odata.o(rootGetters)
      .get('products')
      .query({
        $expand: 'masterProduct,brand,shop',
        $filter: `masterProduct/categoryId eq ${categoryId}`,
      });
    return data;
  },
  async LOAD_productsByShopId({ rootGetters }, shopId) {
    const data = await odata.o(rootGetters)
      .get('products')
      .query({
        $expand: 'masterProduct,brand,shop',
        $filter: `shopId eq ${shopId}`,
      });
    return data;
  },
  async LOAD_shopProducts({ rootGetters }, shopId) {
    const data = await odata.o(rootGetters)
      .get(`shops(${shopId})/GetProducts`)
      .query({
        $expand: 'masterProduct,brand,shop',
      });
    return data;
  },
};

export default { ...actions, ...customActions };
