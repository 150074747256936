<template>
  <div class="text-highlight"
    :class="`price-${color}`">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'PriceHighlight',
  props: {
    color: {
      type: String,
      default: 'default',
    },
  },
};
</script>
