export default {
  ADD_basketItem(state, { masterProduct, quantity }) {
    state.basketItems.push({
      masterProduct,
      quantity,
      shopId: undefined,
      price: undefined,
    });
  },
  INCREASE_basketItemQuantity(state, { product, quantity }) {
    state.basketItems = state.basketItems.map((item) => {
      if (item.product.id === product.id) {
        return {
          product,
          quantity: item.quantity + quantity,
        };
      }
      return item;
    });
  },
  DECREASE_basketItemQuantity(state, { product, quantity }) {
    state.basketItems = state.basketItems.map((item) => {
      if (item.product.id === product.id) {
        if (item.quantity - quantity <= 0) {
          return null;
        }
        return {
          product,
          quantity: item.quantity - quantity,
        };
      }
      return item;
    });
    state.basketItems = state.basketItems.filter((item) => item !== null);
  },
  REMOVE_basketItem(state, { masterProduct }) {
    console.debug(masterProduct);
    state.basketItems = state.basketItems
      .filter((item) => item.masterProduct.id !== masterProduct.id);
  },
  CLEAR_basketItems(state) {
    state.basketItems = [];
  },
  UPDATE_basketItem(state, { basketItem, shop, product }) {
    const item = state.basketItems.find((i) => i.masterProduct.id === basketItem.masterProduct.id);
    const index = state.basketItems.indexOf(item);
    console.debug(index);
    item.shopId = shop.id;
    item.shop = shop;
    item.price = product.price;
    item.subTotal = product.price * basketItem.quantity;

    const basketItems = [...state.basketItems];
    basketItems[index] = item;
    state.basketItems = basketItems;
  },
};
