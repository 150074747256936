<template>
  <v-card
    v-if="item"
    class="h-100 w-100"
    :class="{ 'pa-0': $vuetify.breakpoint.mobile }"
  >
    <div class="master-product-special-percentage">
      {{ specialPercentage }}% off
    </div>
    <v-card-text class="h-100 d-flex flex-column pa-3">
      <v-row>
        <v-col cols="12" class="pa-0">
          <v-img
            class="d-flex flex-column align-end master-product-image"
            :src="item.imageUrl"
            stretch
            height="350px"
            width="100%"
          >
            <div class="master-product-title">
              <div class="text-h6 font-weight-bold">
                {{ item.name }}
              </div>
            </div>
          </v-img>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="d-flex flex-column align-end">
          <div
            :class="isMobile ? 'text-h6' : 'text-h5'"
            class="text-right"
            style="text-decoration: line-through;">
            {{ formatCurrency(item.maxPrice) }}
          </div>
          <div
            :class="isMobile ? 'text-h4' : 'text-h5'"
            class="text-right">
            {{ formatCurrency(item.minPrice) }}
          </div>
          <div class="d-flex align-start justify-space-between">
            <v-spacer></v-spacer>
            <ButtonAddToCart
              :shops="item.shops"
              @add="addToCart($event, item)"
              @addShop="addToCartShop($event, item)"
            />
          </div>
          <router-link
            v-for="(shop, index) in item.shops"
            :to="{ name: 'suppliers-ShopPage', query: { shop: shop.id }}" :key="index">
            <v-img :src="shop.bannerImageUrl" contain width="25%" />
          </router-link>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapMutations } from 'vuex';
import display from '@/mixins/display';
import ButtonAddToCart from './ButtonAddToCart.vue';

export default {
  name: 'MasterProductCard',
  mixins: [display],
  props: {
    /**
         * Set the product item. Default: undefined,
         */
    item: {
      type: Object,
      default: undefined,
    },
  },
  components: { ButtonAddToCart },
  computed: {
    specialPercentage() {
      const { item } = this;
      const { minPrice, maxPrice } = item;
      const percentage = Math.round((1 - (minPrice / maxPrice)) * 100);
      return percentage;
    },
  },
  methods: {
    ...mapMutations('basket', ['ADD_basketItem']),
    addToCart({ quantity }) {
      const { item } = this;
      this.ADD_basketItem({
        masterProduct: item,
        quantity,
      });
    },
    addToCartShop({ quantity, shop }, item) {
      this.ADD_basketItem({
        masterProduct: item,
        quantity,
        selectedShop: shop.id,
      });
    },
  },
};
</script>
