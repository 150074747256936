import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('shop', 'shops');

const customActions = {
  async LOAD_activeShops({ commit, rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('active')
      .query();
    commit('SET_shops', data);
    return data;
  },
};

export default { ...actions, ...customActions };
