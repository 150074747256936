<template>
  <v-container fluid>
    <v-row class="background justify-center align-center mt-6" no-gutters>
      <v-col cols="12" lg="4" xl="4">
        <v-card flat>
          <div class="text-center">
            <v-icon
              color="primary"
              aria-label="My Account" role="img" aria-hidden="false" size="100px">
              {{ mdiShoppingSearch }}
            </v-icon>
            <div class="text-h2 text-center">
              Wys jou Prys
            </div>
          </div>
          <transition name="bounce">
            <v-card-text v-if="!mode" key="user">
                <transition name="bounce">
                  <v-btn
                    class="mb-5"
                    block
                    color="primary"
                    @click="mode = 'user'"
                    data-cy="register-user"
                  >
                    Register as a User
                  </v-btn>
                </transition>
                <v-btn
                  block
                  color="secondary"
                  @click="mode = 'vendor'"
                  data-cy="register-vendor"
                >
                  Register as a Vendor
                </v-btn>
            </v-card-text>
            <v-card-text v-else key="vendor">
                <Register v-if="mode === 'user'" />
                <RegisterVendor v-if="mode === 'vendor'" />
              <v-btn
                v-if="mode"
                block
                color="default"
                @click="mode = undefined"
                type="button"
              >
                Back
              </v-btn>
            </v-card-text>
          </transition>
          <v-container>
            <v-row class="mt-0" justify="center">
              <h4 class="mb-5" justify="center">
                <span>{{ $t('login.or') }}</span>
              </h4>
            </v-row>
            <v-row class="mt-0" justify="center">
              <v-btn text class="title" :to="{ name: 'auth-Login' }">
                {{ $t('register.login') }}
              </v-btn>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mdiOpenInNew, mdiShoppingSearch } from '@mdi/js';
import Register from '../components/Register.vue';
import RegisterVendor from '../components/RegisterVendor.vue';

export default {
  name: 'register',
  components: {
    Register,
    RegisterVendor,
  },
  data() {
    return {
      mode: undefined,
      mdiOpenInNew,
      mdiShoppingSearch,
      counterInterval: undefined,
      backgroundName: 'main-background1',
    };
  },
};
</script>
