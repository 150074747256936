<template>
  <div class="pa-3">
    <MenuCategories />
    <v-card class="mt-5" v-if="shop">
      <v-row>
        <v-col cols="3" class="pa-6 d-flex flex-column align-start">
          <div class="text-h6" v-html="shop.addressLine1" />
          <div class="text-h6" v-html="shop.addressLine2" />
          <div class="text-h6" v-html="shop.addressLine3" />
          <div class="text-h6" v-html="shop.city" />
        </v-col>
        <v-col cols="6" class="pa-6">
          <v-img v-if="shop.bannerImageUrl" :src="shop.bannerImageUrl" height="200px" contain />
          <v-card-title v-else class="text-h2">
            {{ shop.name }}
          </v-card-title>
        </v-col>
        <v-col cols="3" class="d-flex justify-end">
            <v-btn small text :href="shop.faceBookUrl" target="_blank">
              <v-icon color="primary"> {{ mdiFacebook }}</v-icon>
            </v-btn>
            <v-btn small text :href="shop.instagramUrl" target="_blank">
              <v-icon color="primary">{{ mdiInstagram }}</v-icon>
            </v-btn>
            <v-btn small text :href="`tel:${shop.contactNumber}`">
              <v-icon color="primary">
                {{ mdiCellphone }}
              </v-icon>
              {{ shop.contactNumber }}
            </v-btn>
          <v-btn small text :href="`mailto:${shop.email}`">
            <v-icon color="primary">
              {{ mdiMailbox }}
            </v-icon>
            {{ shop.email }}
          </v-btn>
        </v-col>
      </v-row>
      <v-card-text>

      <!-- Specials Tiles -->
      <v-row class="mt-3 mb-5 pl-2">
        <v-col v-for="product in products" :key="product.id" cols="12" md="2">
          <ProductCard :item="product" />
        </v-col>
      </v-row>

      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  mdiCellphone, mdiFacebook, mdiInstagram, mdiMailbox,
} from '@mdi/js';
import MenuCategories from '@/components/MenuCategories.vue';
import ProductCard from '../../../products/src/components/ProductCard.vue';

export default {
  name: 'ShopPage',
  components: {
    MenuCategories,
    ProductCard,
  },
  data() {
    return {
      mdiCellphone,
      mdiFacebook,
      mdiInstagram,
      mdiMailbox,
      shopId: this.$route.query.shop,
      products: [],
    };
  },
  computed: {
    ...mapState('shops', ['shop']),
  },
  watch: {
    '$route.query.shop': {
      immediate: true,
      handler(shopId) {
        this.shopId = shopId;
        this.refresh();
      },
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    ...mapActions('shops', ['LOAD_shop']),
    ...mapActions('products', ['LOAD_shopProducts']),
    async refresh() {
      await this.LOAD_shop(this.shopId);
      this.products = await this.LOAD_shopProducts(this.shopId);
    },
  },
};
</script>
