import { o } from 'odata';
import { authHub, errorHub } from '@codehq/aurora-app-core/src';
import { Auth } from '@codehq/aurora-auth';

const auth = new Auth({
  baseUrl: process.env.VUE_APP_APIURL,
  client_id: process.env.VUE_APP_CLIENT_ID,
  client_secret: process.env.VUE_APP_CLIENT_SECRET,
});

const odata = {
  o(rootGetters, token) {
    let accessToken = token;
    if (!accessToken) {
      console.warn('No access token found, trying to get one from auth');
      accessToken = auth.getToken()?.access_token;
    }
    const organization = rootGetters['organizations/getOrganization'];
    return o(`${process.env.VUE_APP_APIURL ?? 'http://localhost'}/odata/`, this.getConf(accessToken, organization));
  },
  getConf: (token, organization) => ({
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      organizationid: `${organization?.id}`,
    }),
    onError: (x, e) => {
      if (e.status === 401) {
        authHub.$emit('network:unauthorized', {
          source: 'odata',
          data: e,
        });
      } else {
        errorHub.$emit('network.error', e);
      }
    },
  }),
  parseId: (id) => {
    let i = id;
    if (Number.isNaN(id)) {
      i = `'${id}'`;
    }
    return i;
  },
};
export { odata };

export default {};
