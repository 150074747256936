<template>
  <v-container fluid>
    <v-card>
      <v-card-title primary-title>
        Categories
      </v-card-title>
      <v-card-text>
        <v-btn small color="success" @click="dialog = true">Add top level category</v-btn>
        <v-treeview
          :items="organizedCategories"
          :item-key="'id'"
          :item-text="'name'"
          :item-children="'children'"
          dense
        >
          <template v-slot:label="{ item }">
            {{ item.name  }}
          </template>
          <template v-slot:append="{ item }">
            <v-icon @click="remove(item)">{{ mdiTrashCan }}</v-icon>
          </template>
          <template v-slot:prepend="{ item }">
            <v-icon @click="addCategory(item)">{{ mdiPlus }}</v-icon>
          </template>
        </v-treeview>
        <v-dialog
          v-model="dialog"
          scrollable
          max-width="500px"
          transition="dialog-transition"
        >
          <v-card>
            <v-card-text>
              <FormCategory
                :Category="editCategory"
                :isSaving="isSaving"
                @save="save"
                @cancel="dialog = false"
              />
                </v-card-text>
          </v-card>
        </v-dialog>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { mdiPlus, mdiTrashCan } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormCategory from '../components/FormCategory.vue';

export default {
  name: 'ListCategory',
  module: 'Products',
  components: {
    FormCategory,
  },
  mixins: [loading],
  meta: {
    breadcrumb:
      [
        {
          text: 'menu.home',
          to: { name: 'app-Home' },
        },
        {
          text: 'menu.categories',
          to: { name: 'products-ListCategory' },
          disabled: true,
        },
      ],
  },
  data() {
    return {
      /**
      * Icons
      */
      mdiPlus,
      mdiTrashCan,
      /**
       * Show or hide the form dialog
       */
      dialog: false,
      /**
      * Get/Set editing Category. Default to {}.
      */
      editCategory: {},
      /**
       * Mode for saving categories
       */
      mode: 'add',
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapGetters('categories', ['organizedCategories']),
    ...mapState('categories', ['categories']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: this.$$t('parent'),
          value: 'parent.name',
        },
        {
          text: this.$$t('name'),
          value: 'name',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_categories();
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('categories', [
      'LOAD_category',
      'CREATE_category',
      'UPDATE_category',
      'LOAD_categories',
      'DELETE_category',
    ]),
    addCategory(item) {
      this.editCategory = {
        parentId: item.id,
      };
      this.dialog = true;
    },
    /** Method used to save the item
     */
    async save(category) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_category(category);
      } else {
        await this.UPDATE_category(category);
      }
      this.isSaving = false;
      this.dialog = false;
      this.editCategory = {};
    },
    /** Delete Category from the collection
     */
    async remove(category) {
      console.debug(category);
      if (category.children?.length > 0) {
        await this.$confirm('You cannot delete a category with sub categories', {
          buttonFalseText: '',
          buttonTrueText: 'Ok',
        });
        return;
      }
      const res = await this.$confirm('Are you sure you want to delete this category?');
      if (!res) {
        return;
      }
      this.isLoading = true;
      await this.DELETE_category(category.id);
      this.isLoading = false;
    },
  },
};
</script>

<docs>
# Category example

'''js
<Category />
'''
</docs>
