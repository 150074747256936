<template>
  <v-card v-if="tasks.length > 0">
    <v-card-title primary-title>
      Quick Start
    </v-card-title>
    <v-card-text>
      <template v-for="(task, i) in tasks">
        <v-divider
          v-if="i !== 0"
          :key="`${i}-divider`"
        ></v-divider>
        <v-list-item :key="`${i}-${task.text}`" dense>
          <v-list-item-avatar>
            <v-checkbox
              v-model="task.done"
              :color="task.done && 'grey' || 'secondary'"
              disabled
            >
            </v-checkbox>
          </v-list-item-avatar>
          <v-list-item-content>
            <div
              :class="(task.done || !task.ready) && 'grey--text' || 'secondary--text'"
              class="ml-4 mr-4"
              v-text="task.text"
            ></div>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              v-if="task.ready"
              :to="{ name: task.path }"
              small
              color="primary">
              Start
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiCheck } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'Quickstart',
  module: 'Suppliers',
  mixins: [loading],
  data() {
    return {
      mdiCheck,
      baseTasks: [
        {
          done: false,
          ready: true,
          text: 'Upload your branding',
          path: 'suppliers-ShopHome',
        },
        {
          done: false,
          ready: false,
          text: 'Upload your catalog',
          path: 'suppliers-ShopHome',
        },
        {
          done: false,
          ready: false,
          text: 'Activate your store',
          path: 'suppliers-ShopHome',
        },
      ],
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('products', ['products']),
    ...mapState('shop', ['shop']),
    tasks() {
      const tasks = this.baseTasks;
      if (this.shop?.bannerImageUrl) {
        tasks[0].done = true;
        tasks[0].ready = false;
        tasks[1].ready = true;
      }
      if (this.products.length > 0) {
        tasks[1].done = true;
        tasks[1].ready = false;
        tasks[2].ready = true;
      }
      if (this.shop?.isActive) {
        tasks[2].done = true;
        tasks[2].ready = false;
      }
      return tasks;
    },
  },
  async mounted() {
    this.isLoading = true;
    await Promise.all([
      (async () => {
        await this.LOAD_shop();
      })(),
      (async () => {
        await this.LOAD_products();
      })(),
    ]);
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('products', ['LOAD_products']),
    ...mapActions('shop', ['LOAD_shop']),
  },
};
</script>
