<template>
<EditPage :title="mode === 'edit' ? 'Edit Master Product' : 'New Master Product'">
  <v-skeleton-loader
    :loading="isLoading"
    transition="expand-transition"
    type="article"
  >
    <FormMasterProduct
      :MasterProduct="editMasterProduct"
      :isSaving="isSaving"
      @save="save"
      @cancel="$router.push({name: from.name })" />
  </v-skeleton-loader>
</EditPage>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import EditPage from '@/layouts/EditPage.vue';
import FormMasterProduct from '../components/FormMasterProduct.vue';

export default {
  components: {
    EditPage,
    FormMasterProduct,
  },
  name: 'EditMasterProduct',
  module: 'Products',
  mixins: [loading],
  meta: {
    breadcrumb:
      [{
        text: 'menu.home',
        to: { name: 'app-Home' },
      }, {
        text: 'menu.masterProduct',
        to: { name: 'products-ListMasterProduct' },
      }, {
        text: 'menu.edit',
        to: { name: 'products-EditMasterProduct' },
        disabled: true,
      },
      ],
  },
  data() {
    return {
      /**
      * Get/Set editing MasterProduct. Default to {}.
      */
      editMasterProduct: {},
      /**
       * Query Id for MasterProduct
       */
      masterProductId: this.$route?.query.id,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('masterProducts', ['masterProducts', 'masterProduct']),
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.masterProductId) {
        return 'edit';
      }
      return 'add';
    },
  },
  async mounted() {
    this.isLoading = true;
    if (this.mode === 'edit') {
      await this.LOAD_masterProduct(this.masterProductId);
      this.editMasterProduct = this.masterProduct;
    }
    await Promise.all([
      (async () => {
        await this.LOAD_brands();
      })(),
      (async () => {
        await this.LOAD_categories();
      })(),
    ]);
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('brands', ['LOAD_brands']),
    ...mapActions('categories', ['LOAD_categories']),
    ...mapActions('masterProducts', ['LOAD_masterProduct', 'CREATE_masterProduct', 'UPDATE_masterProduct']),
    /** Method used to save the item
     */
    async save(masterProduct) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_masterProduct(masterProduct);
      } else {
        await this.UPDATE_masterProduct(masterProduct);
      }
      this.isSaving = false;
      this.$router.push({ name: 'products-ListMasterProduct' });
    },
  },
};
</script>

<docs>
# EditMasterProduct example

'''js
<EditMasterProduct :MasterProduct="MasterProduct" />
'''
</docs>
