<template>
  <div class="pa-3">
    <v-row class="mb-10">
      <v-col cols="12">
        <ComparisonItems />
      </v-col>
    </v-row>
    <v-stepper v-model="e1">
      <v-stepper-header>
        <v-stepper-step
          :complete="e1 > 1"
          step="1"
        >
          Delivery Address
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          :complete="e1 > 2"
          step="2"
        >
          Billing
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="3">
          Confirmation
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card
            class="mb-12"
          >
            <v-card-text>
              <ShippingAddress />
            </v-card-text>
            <v-card-actions>
              <v-btn text>
                Cancel
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                @click="e1 = 2"
              >
                Continue
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card
            class="mb-12"
            height="200px"
          >
            <v-card-text>
              <ShippingAddress />
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="primary"
                @click="e1 = 3"
              >
                Continue
              </v-btn>

              <v-btn text>
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card
            class="mb-12"
            color="grey lighten-1"
            height="200px"
          >
            <v-card-text>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="primary"
                disabled
              >
                Continue
              </v-btn>

              <v-btn text>
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import ComparisonItems from '../components/ComparisonItems.vue';
import ShippingAddress from '../components/ShippingAddress.vue';

export default {
  name: 'CheckoutPage',
  components: {
    ComparisonItems,
    ShippingAddress,
  },
  meta: {
    breadcrumb:
      [{
        text: 'menu.home',
        to: { name: 'app-Home' },
      }, {
        text: 'menu.checkout',
        disabled: true,
      },
      ],
  },
  data() {
    return {
      e1: 1,
      billingAddress: {
        firstName: '',
        lastName: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zipCode: '',
        country: '',
        phone: '',
      },
    };
  },
};
</script>
