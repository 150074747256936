<template>
  <v-form
    lazy-validation
    v-model="valid"
    @submit.prevent="submit"
    ref="form"
  >
      <v-container class="text-center">
        <v-row>
          <v-col cols="12">
            <v-text-field
              filled
              data-cy="FormBrand-name"
              :label="$$t('name')"
              v-model="localBrand.name"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-img contain v-if="localBrand.imageUrl" :src="localBrand.imageUrl" />
            <ImageUpload label="Logo" v-model="localBrand.imageUrl" />
          </v-col>
        </v-row>
        <div class="d-flex justify-end mb-6">
          <v-btn color="default" outlined class="ml-4 btn-cancel" @click="cancel"
            data-cy="FormBrand-button-cancel">
            {{ $t('common.buttons.cancel') }}
          </v-btn>
          <v-btn color="primary" type="submit" class="ml-4" :loading="isSaving"
            data-cy="FormBrand-button-save">
              {{ $t('common.buttons.save') }}
            </v-btn>
        </div>
      </v-container>
  </v-form>
</template>

<script>
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'FormBrand',
  module: 'Products',
  mixins: [loading],
  props: {
    /**
    * Set the text box value. Default: undefined,
    */
    Brand: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      /**
      * Hold form validation state
      */
      valid: false,
      /**
      * Local value used for property localBrand
      */
      localBrand: {},
    };
  },
  watch: {
    Brand() {
      this.localBrand = this.Brand;
    },
  },
  created() {
    this.localBrand = this.Brand;
  },
  methods: {
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    submit() {
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localBrand);
        this.isSaving = true;
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<docs>
# FormBrand example

'''js
<FormBrand :Brand="Brand" />
'''
</docs>
