<template>
  <v-card class="h-100 w-100">
    <v-card-title primary-title>
      Suppliers
      <v-spacer></v-spacer>
      <v-btn color="secondary" small :to="{ name: 'suppliers-ListSupplier'}">
        View All
      </v-btn>
    </v-card-title>
    <v-card-actions>
      <v-list>
        <v-list two-line>
          <v-list-item
            v-for="supplier in suppliers"
            :key="supplier.id"
            color="secondary"
          >
            <v-list-item-content>
              <router-link :to="{
                name: 'suppliers-EditSupplier',
                query: { id: supplier.id,
                }}"
                color="secondary">
                <v-list-item-title>{{ supplier.name }}</v-list-item-title>
              </router-link>
              <v-list-item-subtitle>{{ supplier.telephoneNumber }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-list>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'SuppliersCard.vue',
  computed: {
    /** Vuex states
     */
    ...mapState('suppliers', ['suppliers']),
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_suppliers();
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('suppliers', ['LOAD_suppliers', 'DELETE_supplier']),
    /** Delete Supplier from the collection
     */
    async remove(supplier) {
      this.isLoading = true;
      await this.DELETE_supplier(supplier.id);
      this.isLoading = false;
    },
  },
};
</script>
