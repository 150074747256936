import {
  mdiBriefcaseOutline,
  mdiEmoticonHappyOutline,
  mdiAccountSearch,
  mdiWatermark,
  mdiPackageCheck,
  mdiShape,
} from '@mdi/js';
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('auth', ['isInRole']),
    menuItems() {
      const userSettings = [];
      let adminSettings = [];
      if (this.isInRole('Administrators')) {
        adminSettings = [{
          name: 'menu.feedbackItems', pathName: 'admin-FeedbackItems', icon: mdiEmoticonHappyOutline, topMenu: false,
        },
        {
          name: 'menu.masterProduct', pathName: 'products-ListMasterProduct', icon: mdiPackageCheck, topMenu: false,
        },
        {
          name: 'menu.brands', pathName: 'products-ListBrand', icon: mdiWatermark, topMenu: false,
        },
        {
          name: 'menu.categories', pathName: 'products-ListCategory', icon: mdiShape, topMenu: false,
        },
        {
          name: 'menu.users', pathName: 'admin-Users', icon: mdiAccountSearch, topMenu: false,
        },
        {
          name: 'menu.suppliers', pathName: 'suppliers-ListSupplier', icon: mdiBriefcaseOutline, topMenu: false,
        },
        ];
      }
      return [...userSettings, ...adminSettings];
    },
  },
};
