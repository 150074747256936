export default {
  SET_isLoggedIn(state, value) {
    state.isLoggedIn = value;
  },
  SET_userId(state, value) {
    state.userId = value;
  },
  SET_username(state, value) {
    state.username = value;
  },
  SET_roles(state, value) {
    if (value) {
      state.roles = value?.split(',');
    } else {
      state.roles = [];
    }
  },
};
