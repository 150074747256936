<template>
<v-container fluid>
  <v-data-table
    :headers="headers"
    :items="masterProducts"
    class="elevation-1"
    :loading="isLoading"
    :search="search"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-btn
          color="success"
          data-cy="MasterProduct-button-new"
          @click="$router.push({
            name: 'products-EditMasterProduct'
          })"
        >
          <v-icon class="mr-2">
            {{ mdiPlus }}
          </v-icon>
          {{ $t('common.buttons.new') }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          class="mt-5"
          :append-icon="mdiMagnify"
          label="Search"
        ></v-text-field>
      </v-toolbar>
    </template>
      <template v-slot:item.imageUrl="{ item }">
        <v-img
          v-if="item.imageUrl"
          :src="item.imageUrl"
          :alt="item.name"
          max-width="100"
          max-height="100"
        />
      </template>
    <template v-slot:item.actions="{ item }">
      <TableBasicButtons :editLink="{
          name: 'products-EditMasterProduct',
          query: {id: item.id}
        }"
        @delete="remove(item)"
      />
    </template>
  </v-data-table>
</v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiPlus, mdiMagnify } from '@mdi/js';
import TableBasicButtons from '@codehq/aurora-app-core/src/components/TableBasicButtons.vue';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'ListMasterProduct',
  module: 'Products',
  components: {
    TableBasicButtons,
  },
  mixins: [loading],
  meta: {
    breadcrumb:
      [{
        text: 'menu.home',
        to: { name: 'app-Home' },
      }, {
        text: 'menu.masterProduct',
        to: { name: 'products-ListMasterProduct' },
        disabled: true,
      },
      ],
  },
  data() {
    return {
      /**
      * Icons
      */
      mdiPlus,
      mdiMagnify,
      /**
      * Search string
      */
      search: '',
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('masterProducts', ['masterProducts']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: '',
          value: 'imageUrl',
        },
        {
          text: this.$$t('category'),
          value: 'category.name',
        },
        {
          text: this.$$t('brand'),
          value: 'brand.name',
        },
        {
          text: this.$$t('name'),
          value: 'name',
        },
        {
          text: this.$$t('description'),
          value: 'description',
        },
        {
          text: this.$$t('size'),
          value: 'size',
        },
        // {
        //   text: this.$$t('stockOnHand'),
        //   value: 'stockOnHand',
        // },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_masterProductsExpanded();
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('masterProducts', ['LOAD_masterProductsExpanded', 'DELETE_masterProduct']),
    /** Delete MasterProduct from the collection
     */
    async remove(masterProduct) {
      this.isLoading = true;
      await this.DELETE_masterProduct(masterProduct.id);
      this.isLoading = false;
    },
  },
};
</script>

<docs>
# MasterProduct example

'''js
<MasterProduct />
'''
</docs>
