<template>
  <v-form>
    <v-row>
      <v-col cols="12" sm="6">
        <v-text-field v-model="shippingAddress.firstName" label="First Name" required />
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field v-model="shippingAddress.lastName" label="Last Name" required />
      </v-col>
      <v-col cols="12">
        <v-textarea v-model="shippingAddress.addressLine1" label="Address Line" required />
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field v-model="shippingAddress.city" label="City" required />
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field v-model="shippingAddress.zipCode" label="Postal Code" required />
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field v-model="shippingAddress.phone" label="Phone" required />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  name: 'ShippingAddress',

  data() {
    return {
      shippingAddress: {
        firstName: '',
        lastName: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zipCode: '',
        country: '',
        phone: '',
      },
    };
  },
};
</script>
