import axios from 'axios';

export default {
  async CREATE_userFile({ rootState }, file) {
    const formData = new FormData();

    formData.append('file', file);

    const { organization } = rootState.organizations;
    const result = await axios.post('/auroraApi/Files', formData, {
      headers: {
        OrganizationId: organization.id,
        'Content-Type': 'multipart/form-data',
      },
    });
    return result.data;
  },
};
