<template>
  <v-container fluid>
    <v-card>
      <v-card-text>
        <v-skeleton-loader
          :loading="isLoading"
          transition="expand-transition"
          type="article"
        >
          <FormSupplier
            :Supplier="editSupplier"
            :isSaving="isSaving"
            @save="save"
            @cancel="$router.push({name: from.name })" />
        </v-skeleton-loader>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormSupplier from '../components/FormSupplier.vue';

export default {
  components: {
    FormSupplier,
  },
  name: 'EditSupplier',
  module: 'Suppliers',
  mixins: [loading],
  meta: {
    redirectOnRefresh: { name: 'suppliers-ListSupplier' },
    breadcrumb:
      [{
        text: 'menu.home',
        to: { name: 'app-Home' },
      },
      {
        text: 'menu.suppliers',
        to: { name: 'suppliers-ListSupplier' },
      },
      {
        text: 'menu.edit',
      },
      ],
  },
  data() {
    return {
      /**
      * Get/Set editing Supplier. Default to {}.
      */
      editSupplier: {},
      /**
       * Query Id for Supplier
       */
      supplierId: this.$route?.query.id,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('suppliers', ['suppliers', 'supplier']),
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.supplierId) {
        return 'edit';
      }
      return 'add';
    },
  },
  async mounted() {
    this.isLoading = true;
    if (this.mode === 'edit') {
      await this.LOAD_supplier(this.supplierId);
      this.editSupplier = this.supplier;
    }
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('suppliers', ['LOAD_supplier', 'CREATE_supplier', 'UPDATE_supplier']),
    /** Method used to save the item
     */
    async save(supplier) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_supplier(supplier);
      } else {
        await this.UPDATE_supplier(supplier);
      }
      this.isSaving = false;
      this.$router.push({ name: 'suppliers-ListSupplier' });
    },
  },
};
</script>

<docs>
# EditSupplier example

'''js
<EditSupplier :Supplier="Supplier" />
'''
</docs>
