<template>
  <v-form
    lazy-validation
    v-model="valid"
    @submit.prevent="submit"
    ref="form"
  >
      <v-container class="text-center">
        <v-row>
          <v-col cols="12">
            <v-btn
              color="secondary"
              class="mb-10"
              @click="showBarcodeReader = !showBarcodeReader"
            >
              {{ showBarcodeReader ? 'Close' : 'Open'}} barcode reader
            </v-btn>
            <StreamBarcodeReader
              v-if="showBarcodeReader"
              @decode="onDecode"
              @loaded="onLoaded"
          ></StreamBarcodeReader>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              filled
              :items="brands"
              item-text="name"
              item-value="id"
              data-cy="FormMasterProduct-brandId"
              :label="$$t('brandId')"
              v-model="localMasterProduct.brandId"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              filled
              :items="categories"
              item-text="name"
              item-value="id"
              data-cy="FormMasterProduct-categoryId"
              :label="$$t('categoryId')"
              v-model="localMasterProduct.categoryId"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              filled
              data-cy="FormMasterProduct-barcode"
              :label="$$t('barcode')"
              v-model="localMasterProduct.barcode"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              filled
              data-cy="FormMasterProduct-name"
              :label="$$t('name')"
              v-model="localMasterProduct.name"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-textarea
              filled
              rows="5"
              data-cy="FormMasterProduct-description"
              :label="$$t('description')"
              v-model="localMasterProduct.description"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              filled
              data-cy="FormMasterProduct-unitOfMeasure"
              :label="$$t('unitOfMeasure')"
              v-model="localMasterProduct.unitOfMeasure"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              filled
              data-cy="FormMasterProduct-size"
              :label="$$t('size')"
              v-model="localMasterProduct.size"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-img contain v-if="localMasterProduct.imageUrl" :src="localMasterProduct.imageUrl" />
            <ImageUpload v-model="localMasterProduct.imageUrl" />
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col cols="12">
            <v-text-field
              filled
              data-cy="FormMasterProduct-stockOnHand"
              :label="$$t('stockOnHand')"
              v-model="localMasterProduct.stockOnHand"
            />
          </v-col>
        </v-row> -->
        <div class="d-flex justify-end mb-6">
          <v-btn color="default" outlined class="ml-4 btn-cancel" @click="cancel"
            data-cy="FormMasterProduct-button-cancel">
            {{ $t('common.buttons.cancel') }}
          </v-btn>
          <v-btn color="primary" type="submit" class="ml-4" :loading="isSaving"
            data-cy="FormMasterProduct-button-save">
              {{ $t('common.buttons.save') }}
            </v-btn>
        </div>
      </v-container>
  </v-form>
</template>

<script>
import { mapState } from 'vuex';
import { StreamBarcodeReader } from 'vue-barcode-reader';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'FormMasterProduct',
  components: {
    StreamBarcodeReader,
  },
  module: 'Products',
  mixins: [loading],
  props: {
    /**
    * Set the text box value. Default: undefined,
    */
    MasterProduct: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      /**
       * Show barcode reader
       */
      showBarcodeReader: false,
      /**
      * Hold form validation state
      */
      valid: false,
      /**
      * Local value used for property localMasterProduct
      */
      localMasterProduct: {},
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('brands', ['brands']),
    ...mapState('categories', ['categories']),
  },
  watch: {
    MasterProduct() {
      this.localMasterProduct = this.MasterProduct;
    },
  },
  created() {
    this.localMasterProduct = this.MasterProduct;
  },
  methods: {
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    submit() {
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localMasterProduct);
        this.isSaving = true;
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
    onDecode(text) {
      console.log(`Decode text from QR code is ${text}`);
    },

    onLoaded() {
      console.log('Ready to start scanning barcodes');
    },
  },
};
</script>

<docs>
# FormMasterProduct example

'''js
<FormMasterProduct :MasterProduct="MasterProduct" />
'''
</docs>
